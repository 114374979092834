// import NotificationPartial from "../_partial/Notification.partial";
// import SettingsPartial from "../_partial/Settings.partial";
// import MessagesPartial from "../_partial/Messages.partial";
import CartPartial from "../_partial/Cart.partial";

const DefaultHeaderRightCommon = () => {
  return (
    <>
      <CartPartial />
      {/* <MessagesPartial />
      <NotificationPartial /> */}
      {/* <SettingsPartial /> */}
    </>
  );
};

export default DefaultHeaderRightCommon;
