import type {
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
} from "@apollo/client";
import { useQuery } from "@apollo/client";
import type { DocumentNode } from "graphql";
import { useCallback, useRef, useEffect } from "react";

const DEFAULT_INVALIDATE_AFTER = 30_000;

/**
 * A wrapper around useQuery that will show stale data while loading.
 *
 * @param query - The GraphQL query to run
 * @param options - The query options, forwarded to useQuery
 * @returns The result of the query, with loading set to false even for stale data
 */
export function useData<TData, TVariables extends OperationVariables>(
  query: TypedDocumentNode<TData, TVariables> | DocumentNode,
  options?: QueryHookOptions<TData, TVariables> & {
    invalidateAfter?: number;
  },
): QueryResult<TData, TVariables> {
  const { invalidateAfter = DEFAULT_INVALIDATE_AFTER } = options ?? {};

  const result = useQuery(query, options);
  const lastRefetchAt = useRef(Date.now());

  if (result.loading) {
    const data = result.client.readQuery({
      query: query,
      variables: result.variables,
    });
    if (data) {
      // Rewrite loading to false to show stale but fast data
      result.loading = false;
    }
  }

  // This callback re-fetches the current query if it has not been re-fetched in the last N seconds.
  // We pass it to useOnFocus to re-fetch the query when the app regains focus.
  const onFocus = useCallback(() => {
    const diff = Date.now() - lastRefetchAt.current;
    if (diff > invalidateAfter) {
      lastRefetchAt.current = Date.now();
      result.refetch();
    }
  }, [result, invalidateAfter]);

  useOnFocus({
    onFocus: onFocus,
  });

  return result;
}

function useOnFocus({ onFocus }: { onFocus: () => void }) {
  useEffect(() => {
    const handleFocus = () => {
      onFocus();
    };
    window.addEventListener("focus", handleFocus);
    return () => {
      window.removeEventListener("focus", handleFocus);
    };
  }, [onFocus]);
}
