import Container from "@/components/layouts/Container/Container";
import Header, { HeaderRight } from "@/components/layouts/Header/Header";
import PageWrapper from "@/components/layouts/PageWrapper/PageWrapper";
import Card, { CardBody, CardHeader, CardTitle } from "@/components/ui/Card";
import DefaultHeaderRightCommon from "@/templates/layouts/Headers/_common/DefaultHeaderRight.common";
import { createFileRoute, useSearch } from "@tanstack/react-router";

export const Route = createFileRoute("/payment/fail")({
  component: PaymentFail,
});

function PaymentFail() {
  // search params
  const searchParams: any = useSearch({ from: Route.id });
  const transactionId = searchParams["transaction_id"];
  return (
    <>
      <Header>
        <HeaderRight>
          <DefaultHeaderRightCommon />
        </HeaderRight>
      </Header>
      <PageWrapper name="Payment Failed">
        <Container>
          <Card>
            <CardHeader>
              <CardTitle>Payment Failed</CardTitle>
            </CardHeader>
            <CardBody>
              <div className="bg-gray-100">
                <div className="bg-white p-6 md:mx-auto">
                  <div className="text-center">
                    <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">
                      Payment Failed!
                    </h3>
                    <p className="text-gray-600 my-2">
                      Please try again or contact our support team for
                      assistance.
                    </p>
                    <p> Transaction ID: {transactionId}</p>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Container>
      </PageWrapper>
    </>
  );
}
