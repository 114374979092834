import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import type { } from "@redux-devtools/extension"; // required for devtools typing
import { CartLineItem, User } from "@/gql/graphql";

interface AuthState {
  user: User | null;
  token: string | null;
  refreshToken: string | null;
  setUser: (user: User | null) => void;
  setToken: (token: string | null) => void;
  setRefreshToken: (refreshToken: string | null) => void;
  cart: CartLineItem[];
  setCart: (cart: CartLineItem[]) => void;
}

export const useAuthStore = create<AuthState>()(
  devtools(
    persist(
      (set) => ({
        user: null,
        token: null,
        refreshToken: null,
        cart: [],
        setUser: (user) => set({ user }),
        setToken: (token) => set({ token }),
        setRefreshToken: (refreshToken) => set({ refreshToken }),
        setCart: (cart) => set({ cart }),
      }),
      {
        name: "auth-storage",
      },
    ),
  ),
);
