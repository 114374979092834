import Breadcrumb from "@/components/layouts/Breadcrumb/Breadcrumb";
import Container from "@/components/layouts/Container/Container";
import Header, {
  HeaderLeft,
  HeaderRight,
} from "@/components/layouts/Header/Header";
import PageWrapper from "@/components/layouts/PageWrapper/PageWrapper";
import Button from "@/components/ui/Button";
import Card, {
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/Card";
import Modal, { ModalBody, ModalHeader } from "@/components/ui/Modal";
import {
  Mutation,
  MutationSocialReportUseCaseDownloadSharingReportArgs,
  Query,
  QuerySocialReportUseCaseSharingReportsArgs,
  SharingReport as SharingReportType,
} from "@/gql/graphql";
import {
  SOCIAL_REPORT_USE_CASE_DOWNLOAD_SHARING_REPORT,
  SOCIAL_REPORT_USE_CASE_CATEGORIES,
  SOCIAL_REPORT_USE_CASE_SHARING_REPORTS,
} from "@/gql/schema";
import { useData } from "@/hooks/useData";
import DefaultHeaderRightCommon from "@/templates/layouts/Headers/_common/DefaultHeaderRight.common";
import { useMutation } from "@apollo/client";
import { createFileRoute, redirect } from "@tanstack/react-router";
import classNames from "classnames";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

export const Route = createFileRoute("/sharing-reports")({
  component: SharingReport,
  beforeLoad: ({ context, location }) => {
    if (!context.user) {
      throw redirect({
        to: "/login",
        search: {
          redirect: location.href,
        },
      });
    }
  },
});

function SharingReport() {
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [category, setCategory] = useState<string | null>(null);
  const [selectedReport, setSelectedReport] =
    useState<SharingReportType | null>(null);
  const { data: categoryData } = useData<Query, any>(
    SOCIAL_REPORT_USE_CASE_CATEGORIES,
    {}
  );
  const { data } = useData<Query, QuerySocialReportUseCaseSharingReportsArgs>(
    SOCIAL_REPORT_USE_CASE_SHARING_REPORTS,
    {
      variables: {
        filter: {
          category,
        },
        pagination: {
          limit: pagination.pageSize,
          skip: pagination.pageIndex * pagination.pageSize,
        },
      },
    }
  );
  const [getDownloadFile] = useMutation<
    Mutation,
    MutationSocialReportUseCaseDownloadSharingReportArgs
  >(SOCIAL_REPORT_USE_CASE_DOWNLOAD_SHARING_REPORT);

  const downloadFile = async (id: string) => {
    try {
      const res = await getDownloadFile({
        variables: {
          id,
        },
      });
      if (res?.data?.socialReportUseCaseDownloadSharingReport?.downloadUrl) {
        window.open(
          res.data.socialReportUseCaseDownloadSharingReport.downloadUrl,
          "_blank"
        );
        return;
      }
      toast.error("Failed to download file");
    } catch (error: any) {
      toast.error(error?.message ?? "Failed to download file");
    }
  };

  return (
    <>
      <Header>
        <HeaderLeft>
          <Breadcrumb
            path="Home / Sharing Reports"
            currentPage="Sharing Reports"
          />
        </HeaderLeft>
        <HeaderRight>
          <DefaultHeaderRightCommon />
        </HeaderRight>
      </Header>
      <PageWrapper name="Sharing Reports">
        <Container className="grid grid-cols-12 gap-[16px]">
          <div className="col-span-2">
            <ul className="text-base font-medium text-gray-900 bg-white border border-gray-200 rounded-lg overflow-hidden">
              <li
                className={classNames(
                  "w-full px-4 py-2 border-b border-gray-200 hover:bg-[#3b82f6] hover:text-white cursor-pointer",
                  {
                    "bg-[#3b82f6] text-white": !category,
                  }
                )}
                onClick={() => setCategory(null)}
              >
                All
              </li>
              {categoryData?.socialReportUseCaseCategories?.map((item: any) => (
                <li
                  className={classNames(
                    "w-full px-4 py-2 border-b border-gray-200 hover:bg-[#3b82f6] hover:text-white cursor-pointer",
                    {
                      "bg-[#3b82f6] text-white": category === item.id,
                    }
                  )}
                  onClick={() => setCategory(item.id)}
                >
                  {item.icon}&nbsp;{item.name}
                </li>
              ))}
            </ul>
          </div>
          <div className="col-span-10">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-[16px]">
              {data?.socialReportUseCaseSharingReports?.length === 0 && (
                <div className="col-span-full">No Report Found</div>
              )}
              {data?.socialReportUseCaseSharingReports?.map((item) => (
                <Card
                  onClick={() => setSelectedReport(item)}
                  key={item.id}
                  className="cursor-pointer"
                >
                  <CardHeader>
                    <CardTitle>{item.name}</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <img
                      className="aspect-[9/12] w-full h-[200px] rounded object-cover"
                      src={item.thumbnail ?? ""}
                      alt=""
                    />
                    <div className="mt-[8px] text-sm">
                      {item.shortDescription}
                    </div>
                  </CardBody>
                  <CardFooter>
                    <div className="flex items-center justify-between w-full">
                      <Button
                        onClick={() => setSelectedReport(item)}
                        variant="solid"
                        icon="HeroEye"
                        className="w-min"
                      >
                        View
                      </Button>
                      <Button
                        icon="HeroArrowDownTray"
                        onClick={() => downloadFile(item.id)}
                        variant="solid"
                        className="w-min"
                      >
                        Download
                      </Button>
                    </div>
                  </CardFooter>
                </Card>
              ))}
            </div>
            <div className="w-full mt-[16px]">
              <div className="flex overflow-x-auto sm:justify-center">
                <nav className="sticky top-[82px]">
                  <ul className="xs:mt-0 mt-2 inline-flex items-center -space-x-px">
                    <li>
                      <button
                        onClick={() =>
                          setPagination({
                            ...pagination,
                            pageIndex: pagination.pageIndex - 1,
                          })
                        }
                        type="button"
                        className="w-[90px] ml-0 rounded-l-lg border border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white"
                        disabled={pagination.pageIndex === 0}
                      >
                        Previous
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() =>
                          setPagination({
                            ...pagination,
                            pageIndex: pagination.pageIndex - 1,
                          })
                        }
                        type="button"
                        disabled={
                          !data?.socialReportUseCaseSharingReports ||
                          data?.socialReportUseCaseSharingReports?.length <
                            pagination.pageSize
                        }
                        className="w-[90px] rounded-r-lg border border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white"
                      >
                        Next
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <Modal
            isOpen={!!selectedReport}
            setIsOpen={() => setSelectedReport(null)}
            size="lg"
            isCentered
            isScrollable
          >
            {selectedReport ? (
              <ModalHeader>
                <div className="px-4">{selectedReport.name}</div>
              </ModalHeader>
            ) : (
              <></>
            )}
            {selectedReport ? (
              <ModalBody>
                <Card>
                  <CardBody>
                    <div className="grid grid-cols-12 gap-4">
                      <div className="col-span-12 lg:col-span-6">
                        <label
                          data-component-name="Label"
                          className="mb-2 inline-block w-full cursor-pointer text-[16px] font-bold"
                          htmlFor="id"
                        >
                          Category
                        </label>
                        <p className="text-base peer-disabled:pointer-events-none peer-disabled:opacity-50 uppercase">
                          {selectedReport?.categories?.join(", ") ?? ""}
                        </p>
                        <label
                          data-component-name="Label"
                          className="mb-2 inline-block w-full cursor-pointer text-[16px] font-bold"
                          htmlFor="id"
                        >
                          Created Date
                        </label>
                        <p className="text-base peer-disabled:pointer-events-none peer-disabled:opacity-50">
                          {dayjs(selectedReport?.reportDate).format(
                            "DD MMM YYYY"
                          )}
                        </p>
                      </div>
                      <div className="col-span-12 lg:col-span-6 text-left">
                        <img
                          src={selectedReport?.imageUrl ?? ""}
                          className="w-full h-[180px] object-cover float-left"
                        />
                      </div>
                    </div>
                    <div className="w-full mt-[16px]">
                      <label
                        data-component-name="Label"
                        className="mb-2 inline-block w-full cursor-pointer text-[16px] font-bold"
                        htmlFor="id"
                      >
                        Description
                      </label>
                      <p
                        className="text-base peer-disabled:pointer-events-none peer-disabled:opacity-50"
                        dangerouslySetInnerHTML={{
                          __html: selectedReport?.description ?? "",
                        }}
                      ></p>
                    </div>
                  </CardBody>
                  <CardFooter>
                    <Button
                      icon="HeroArrowDownTray"
                      onClick={() => downloadFile(selectedReport.id)}
                      variant="solid"
                      className="whitespace-nowrap w-full"
                    >
                      Download Report
                    </Button>
                  </CardFooter>
                </Card>
              </ModalBody>
            ) : (
              <></>
            )}
          </Modal>
        </Container>
      </PageWrapper>
    </>
  );
}
