import FieldWrap from "@/components/form/FieldWrap";
import Input from "@/components/form/Input";
import Label from "@/components/form/Label";
import Icon from "@/components/icon/Icon";
import Breadcrumb from "@/components/layouts/Breadcrumb/Breadcrumb";
import Container from "@/components/layouts/Container/Container";
import Header, {
  HeaderLeft,
  HeaderRight,
} from "@/components/layouts/Header/Header";
import PageWrapper from "@/components/layouts/PageWrapper/PageWrapper";
import Badge from "@/components/ui/Badge";
import Button from "@/components/ui/Button";
import Card, {
  CardBody,
  CardFooter,
  CardHeader,
  CardHeaderChild,
  CardTitle,
} from "@/components/ui/Card";
import { Mutation, MutationUserEditInfoArgs } from "@/gql/graphql";
import { USER_EDIT_INFO } from "@/gql/schema";
import { useAuthStore } from "@/store/auth";
import DefaultHeaderRightCommon from "@/templates/layouts/Headers/_common/DefaultHeaderRight.common";
import { useMutation } from "@apollo/client";
import { createFileRoute, redirect } from "@tanstack/react-router";
import { useFormik } from "formik";
import toast from "react-hot-toast";

export const Route = createFileRoute("/profile")({
  component: Profile,
  beforeLoad: ({ context, location }) => {
    if (!context.user) {
      throw redirect({
        to: "/login",
        search: {
          redirect: location.href,
        },
      });
    }
  },
});

function Profile() {
  const user = useAuthStore((state) => state.user);
  const [editInfo] = useMutation<Mutation, MutationUserEditInfoArgs>(
    USER_EDIT_INFO
  );
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      familyName: "",
      givenName: "",
    },
    onSubmit: () => {
      editInfo({
        variables: {
          input: {
            familyName: formik.values.familyName,
            givenName: formik.values.givenName,
          },
        },
      });
      toast.success("Profile updated successfully");
    },
  });

  return (
    <>
      <Header>
        <HeaderLeft>
          <Breadcrumb
            path="Home / Facebook Groups"
            currentPage="Facebook Groups"
          />
        </HeaderLeft>
        <HeaderRight>
          <DefaultHeaderRightCommon />
        </HeaderRight>
      </Header>
      <PageWrapper name="Facebook Groups">
        <Container>
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12">
              <h1 className="my-4 font-bold">Edit profile</h1>
            </div>
          </div>
          <Card>
            <CardBody>
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-12 lg:col-span-6">
                  <Label htmlFor="givenName">Given Name</Label>
                  <FieldWrap
                    firstSuffix={<Icon icon="HeroUser" className="mx-2" />}
                  >
                    <Input
                      id="givenName"
                      name="givenName"
                      onChange={formik.handleChange}
                      value={formik.values.givenName}
                      autoComplete="given-name"
                    />
                  </FieldWrap>
                </div>
                <div className="col-span-12 lg:col-span-6">
                  <Label htmlFor="familyName">Family Name</Label>
                  <FieldWrap
                    firstSuffix={<Icon icon="HeroEnvelope" className="mx-2" />}
                  >
                    <Input
                      id="familyName"
                      name="familyName"
                      onChange={formik.handleChange}
                      value={formik.values.familyName}
                      autoComplete="family-name"
                    />
                  </FieldWrap>
                </div>
              </div>
            </CardBody>
            <CardFooter>
              <div className="w-full flex justify-end">
                <Button
                  icon="HeroServer"
                  variant="solid"
                  isDisable={false}
                  onClick={() => formik.handleSubmit()}
                >
                  Save
                </Button>
              </div>
            </CardFooter>
          </Card>
        </Container>
      </PageWrapper>
    </>
  );
}
