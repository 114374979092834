import Icon from "@/components/icon/Icon";
import Breadcrumb from "@/components/layouts/Breadcrumb/Breadcrumb";
import Container from "@/components/layouts/Container/Container";
import Header, {
  HeaderLeft,
  HeaderRight,
} from "@/components/layouts/Header/Header";
import PageWrapper from "@/components/layouts/PageWrapper/PageWrapper";
import Badge from "@/components/ui/Badge";
import Card, {
  CardBody,
  CardHeader,
  CardHeaderChild,
  CardTitle,
} from "@/components/ui/Card";
import Table, { TBody, Td, Th, THead, Tr } from "@/components/ui/Table";
import {
  Billing,
  Query,
  QueryBillingUseCaseBillingHistoriesArgs,
} from "@/gql/graphql";
import { BILLING_USE_CASE_BILLING_HISTORIES } from "@/gql/schema";
import { useData } from "@/hooks/useData";
import { TableCardFooterTemplate } from "@/templates/common/TableParts.template";
import DefaultHeaderRightCommon from "@/templates/layouts/Headers/_common/DefaultHeaderRight.common";
import { createFileRoute, redirect } from "@tanstack/react-router";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useState } from "react";

export const Route = createFileRoute("/billing-history")({
  component: FacebookGroups,
  beforeLoad: ({ context, location }) => {
    if (!context.user) {
      throw redirect({
        to: "/login",
        search: {
          redirect: location.href,
        },
      });
    }
  },
});

const columnHelper = createColumnHelper<Billing>();

const columns = [
  columnHelper.accessor("id", {
    cell: (info) => <div className="text-[14px]">{info.getValue()}</div>,
    header: "ID",
    footer: "ID",
  }),
  columnHelper.accessor("planId", {
    cell: (info) => (
      <div className="text-2xl font-bold uppercase">{info.getValue()}</div>
    ),
    header: "Plan ID",
    footer: "Plan ID",
  }),
  columnHelper.accessor("amount", {
    cell: (info) => (
      <div
        data-component-name="Badge"
        className="inline-flex items-center justify-center px-2 border-2 rounded-lg border-emerald-500 bg-emerald-500/10 text-emerald-500 border-transparent text-xl font-bold"
      >
        {info.getValue()}
        <span className="uppercase ml-[4px]">{info.row.original.currency}</span>
      </div>
    ),
    header: "Amount",
    footer: "Amount",
  }),
  columnHelper.accessor("transactionStatus", {
    cell: (info) => (
      <Badge
        className="uppercase"
        variant={info.getValue() === "success" ? "solid" : "outline"}
      >
        {info.getValue()}
      </Badge>
    ),
    header: "Transaction Status",
    footer: "Transaction Status",
  }),
];

function FacebookGroups() {
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const { data } = useData<Query, QueryBillingUseCaseBillingHistoriesArgs>(
    BILLING_USE_CASE_BILLING_HISTORIES,
    {
      variables: {
        pagination: {
          skip: pagination.pageIndex * pagination.pageSize,
          limit: pagination.pageSize,
        },
      },
    }
  );
  const table = useReactTable({
    data: data?.billingUseCaseBillingHistories ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
  });
  return (
    <>
      <Header>
        <HeaderLeft>
          <Breadcrumb
            path="Home / Billing History"
            currentPage="Billing History"
          />
        </HeaderLeft>
        <HeaderRight>
          <DefaultHeaderRightCommon />
        </HeaderRight>
      </Header>
      <PageWrapper name="Billing History">
        <Container>
          <div className="col-span-12">
            <Card className="h-full">
              <CardHeader>
                <CardHeaderChild>
                  <CardTitle>Billing History</CardTitle>
                </CardHeaderChild>
              </CardHeader>
              <CardBody className="overflow-auto">
                <Table className="table-fixed max-md:min-w-[70rem]">
                  <THead>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <Tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                          <Th
                            key={header.id}
                            isColumnBorder={false}
                            className="text-left"
                          >
                            {header.isPlaceholder ? null : (
                              <div
                                key={header.id}
                                aria-hidden="true"
                                {...{
                                  className: header.column.getCanSort()
                                    ? "cursor-pointer select-none flex items-center"
                                    : "",
                                  onClick:
                                    header.column.getToggleSortingHandler(),
                                }}
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                                {{
                                  asc: (
                                    <Icon
                                      icon="HeroChevronUp"
                                      className="ltr:ml-1.5 rtl:mr-1.5"
                                    />
                                  ),
                                  desc: (
                                    <Icon
                                      icon="HeroChevronDown"
                                      className="ltr:ml-1.5 rtl:mr-1.5"
                                    />
                                  ),
                                }[header.column.getIsSorted() as string] ??
                                  null}
                              </div>
                            )}
                          </Th>
                        ))}
                      </Tr>
                    ))}
                  </THead>
                  <TBody>
                    {table.getRowModel().rows.map((row) => (
                      <Tr key={row.id}>
                        {row.getVisibleCells().map((cell) => (
                          <Td key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Td>
                        ))}
                      </Tr>
                    ))}
                  </TBody>
                </Table>
                <div className="w-full mt-[8px]">
                  <TableCardFooterTemplate table={table} />
                </div>
              </CardBody>
            </Card>
          </div>
        </Container>
      </PageWrapper>
    </>
  );
}
