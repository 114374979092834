import FieldWrap from "@/components/form/FieldWrap";
import Input from "@/components/form/Input";
import Validation from "@/components/form/Validation";
import Icon from "@/components/icon/Icon";
import PageWrapper from "@/components/layouts/PageWrapper/PageWrapper";
import Button from "@/components/ui/Button";
import {
  Mutation,
  MutationAuthLoginWithEmailAndPasswordArgs,
  MutationAuthLoginWithGoogleArgs,
  MutationAuthResendRegistrationEmailArgs,
} from "@/gql/graphql";
import {
  LOGIN_MUTATION,
  LOGIN_WITH_GOOGLE_MUTATION,
  RESEND_REGISTRATION_EMAIL_MUTATION,
} from "@/gql/schema";
import { useAuthStore } from "@/store/auth";
import { GoogleLogin } from "@react-oauth/google";
import { UserStatus } from "@/types/user.type";
import { useMutation } from "@apollo/client";
import {
  createFileRoute,
  Link,
  redirect,
  useRouter,
} from "@tanstack/react-router";
import classNames from "classnames";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";

export const Route = createFileRoute("/login")({
  component: Login,
  beforeLoad: ({ context, location }) => {
    if (context.user) {
      throw redirect({
        to: "/",
        search: {
          redirect: location.href,
        },
      });
    }
  },
});

type TValues = {
  username: string;
  password: string;
};

let userId = "";
function Login() {
  const router = useRouter();
  const [loginMutation, { loading }] = useMutation<
    Mutation,
    MutationAuthLoginWithEmailAndPasswordArgs
  >(LOGIN_MUTATION);
  const [loginWithGoogleMutation] = useMutation<
    Mutation,
    MutationAuthLoginWithGoogleArgs
  >(LOGIN_WITH_GOOGLE_MUTATION);
  const [resendEmailMutation, { loading: resendEmailLoading }] = useMutation<
    Mutation,
    MutationAuthResendRegistrationEmailArgs
  >(RESEND_REGISTRATION_EMAIL_MUTATION);
  const [showResendEmailVerification, setShowResendEmailVerification] =
    useState<boolean>(false);
  const setUser = useAuthStore((state) => state.setUser);
  const setToken = useAuthStore((state) => state.setToken);
  const setRefreshToken = useAuthStore((state) => state.setRefreshToken);
  const [passwordShowStatus, setPasswordShowStatus] = useState<boolean>(false);
  const divRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(300);

  const onLoginWithGoogle = async (token: string) => {
    try {
      const res = await loginWithGoogleMutation({
        variables: {
          token,
        },
      });
      if (res.data?.authLoginWithGoogle?.accessToken) {
        setUser(res.data?.authLoginWithGoogle.user);
        setToken(res.data?.authLoginWithGoogle.accessToken);
        if (res.data?.authLoginWithGoogle.refreshToken) {
          setRefreshToken(res.data?.authLoginWithGoogle.refreshToken);
        }
        router.navigate({
          to: "/",
        });
      }
    } catch (err: any) {
      toast.error(err?.message ?? "Failed to login with Google");
    }
  };

  const onResendEmailVerification = async () => {
    try {
      const res = await resendEmailMutation({
        variables: {
          input: {
            userId,
          },
        },
      });
      if (res.data?.authResendRegistrationEmail) {
        toast.success("Email verification sent");
        return;
      }
    } catch (err: any) {
      toast.error(err?.message ?? "Failed to resend email verification");
    }
  };
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validate: (values: TValues) => {
      const errors: Partial<TValues> = {};

      if (!values.username) {
        errors.username = "Required";
      }

      if (!values.password) {
        errors.password = "Required";
      }

      return errors;
    },
    onSubmit: async (values: TValues, { setFieldError }) => {
      try {
        setShowResendEmailVerification(false);
        const res = await loginMutation({
          variables: {
            input: {
              email: values.username,
              password: values.password,
            },
          },
        });
        if (
          res.data?.authLoginWithEmailAndPassword?.user?.status ===
          UserStatus.Inactive
        ) {
          userId = res.data?.authLoginWithEmailAndPassword?.user?.id ?? "";
          setFieldError(
            "password",
            "Your account is not active, please check your email for verification."
          );
          setShowResendEmailVerification(true);
          return;
        }
        if (res.data?.authLoginWithEmailAndPassword?.accessToken) {
          setUser(res.data?.authLoginWithEmailAndPassword.user);
          setToken(res.data?.authLoginWithEmailAndPassword.accessToken);
          if (res.data?.authLoginWithEmailAndPassword.refreshToken) {
            setRefreshToken(
              res.data?.authLoginWithEmailAndPassword.refreshToken
            );
          }
          router.navigate({
            to: "/",
          });
          return;
        }
      } catch (err: any) {
        setFieldError("password", err?.message ?? "Invalid email or password");
      }
    },
  });

  useEffect(() => {
    if (divRef.current) {
      setWidth(divRef.current.clientWidth);
    }
  }, [divRef.current]);

  return (
    <PageWrapper
      isProtectedRoute={false}
      className="bg-white dark:bg-inherit"
      name="Sign In"
    >
      <div className="container mx-auto flex h-full items-center justify-center">
        <div
          ref={divRef}
          className="flex max-w-sm flex-col gap-8 w-full md:min-w-[380px]"
        >
          <div>{/* <LogoTemplate className='h-12' /> */}</div>
          <div>
            <span className="text-4xl font-semibold">Sign in</span>
          </div>
          <div>
            <span>Sign up with Open account</span>
          </div>
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12">
              <GoogleLogin
                logo_alignment="left"
                onSuccess={(credentialResponse) => {
                  onLoginWithGoogle(credentialResponse.credential ?? "");
                }}
                onError={() => {}}
                containerProps={{
                  className: "google-button w-full",
                  style: {
                    padding: 0,
                    width: "100%",
                  },
                }}
                width={width}
              />
            </div>
            {/* <div className="col-span-6">
              <Button
                icon="CustomApple"
                variant="outline"
                color="zinc"
                size="lg"
                className="w-full"
              >
                Apple
              </Button>
            </div> */}
          </div>
          <div className="border border-zinc-500/25 dark:border-zinc-500/50" />
          <div>
            <span>Or continue with email address</span>
          </div>
          <form className="flex flex-col gap-4" noValidate>
            <div
              className={classNames({
                "mb-2": !formik.isValid,
              })}
            >
              <Validation
                isValid={formik.isValid}
                isTouched={formik.touched.username}
                invalidFeedback={formik.errors.username}
                validFeedback=""
              >
                <FieldWrap
                  firstSuffix={<Icon icon="HeroEnvelope" className="mx-2" />}
                >
                  <Input
                    dimension="lg"
                    id="username"
                    autoComplete="username"
                    name="username"
                    placeholder="Email"
                    className="pl-[32px]"
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </FieldWrap>
              </Validation>
            </div>
            <div
              className={classNames({
                "mb-2": !formik.isValid,
              })}
            >
              <Validation
                isValid={formik.isValid}
                isTouched={formik.touched.password}
                invalidFeedback={formik.errors.password}
                validFeedback=""
              >
                <FieldWrap
                  firstSuffix={<Icon icon="HeroKey" className="mx-2" />}
                  lastSuffix={
                    <Icon
                      className="mx-2 cursor-pointer"
                      icon={passwordShowStatus ? "HeroEyeSlash" : "HeroEye"}
                      onClick={() => {
                        setPasswordShowStatus(!passwordShowStatus);
                      }}
                    />
                  }
                >
                  <Input
                    dimension="lg"
                    type={passwordShowStatus ? "text" : "password"}
                    autoComplete="current-password"
                    id="password"
                    name="password"
                    placeholder="Password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        formik.handleSubmit();
                      }
                    }}
                  />
                </FieldWrap>
              </Validation>
              {showResendEmailVerification && (
                <Button
                  onClick={() => onResendEmailVerification()}
                  variant="solid"
                  className="w-full"
                  isLoading={resendEmailLoading}
                >
                  Resend email verification
                </Button>
              )}
            </div>
            <div>
              <Button
                size="lg"
                variant="solid"
                className="w-full font-semibold"
                isLoading={loading}
                onClick={() => formik.handleSubmit()}
              >
                Sign in
              </Button>
            </div>
          </form>
          {/* <div>
            <span className="text-zinc-500">
              This site is protected by reCAPTCHA and the Google Privacy Policy.
            </span>
          </div> */}
          <div>
            <span className="flex gap-2 text-sm">
              <span className="text-zinc-400 dark:text-zinc-600">
                Don’t have an account?
              </span>
              <Link to="/register" className="hover:text-inherit">
                Sign up
              </Link>
            </span>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
}
