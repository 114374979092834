type TPeriodKEY = "DAY" | "WEEK" | "MONTH";
type TPeriodText = "Day" | "Week" | "Month";

export type TPeriod = {
	text: TPeriodText;
};
export type TPeriods = {
	[key in TPeriodKEY]: TPeriod;
};

export const PERIOD: TPeriods = {
	DAY: { text: "Day" },
	WEEK: { text: "Week" },
	MONTH: { text: "Month" },
};

export interface Range {
	startDate?: Date | undefined;
	endDate?: Date | undefined;
	color?: string | undefined;
	key?: string | undefined;
	autoFocus?: boolean | undefined;
	disabled?: boolean | undefined;
	showDateDisplay?: boolean | undefined;
}
