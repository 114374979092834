import Breadcrumb from "@/components/layouts/Breadcrumb/Breadcrumb";
import Container from "@/components/layouts/Container/Container";
import Header, {
  HeaderLeft,
  HeaderRight,
} from "@/components/layouts/Header/Header";
import PageWrapper from "@/components/layouts/PageWrapper/PageWrapper";
import Button from "@/components/ui/Button";
import Card, {
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/Card";
import {
  Mutation,
  MutationSocialReportUseCaseDownloadSharingReportArgs,
  Query,
  QuerySocialReportUseCaseSharingReportsArgs,
} from "@/gql/graphql";
import {
  SOCIAL_REPORT_USE_CASE_CATEGORIES,
  SOCIAL_REPORT_USE_CASE_DOWNLOAD_SHARING_REPORT,
  SOCIAL_REPORT_USE_CASE_REPORTS,
} from "@/gql/schema";
import { useData } from "@/hooks/useData";
import DefaultHeaderRightCommon from "@/templates/layouts/Headers/_common/DefaultHeaderRight.common";
import { useMutation } from "@apollo/client";
import { createFileRoute, redirect, useRouter } from "@tanstack/react-router";
import classNames from "classnames";
import { useState } from "react";
import toast from "react-hot-toast";

export const Route = createFileRoute("/reports/")({
  component: SocialReport,
  beforeLoad: ({ context, location }) => {
    if (!context.user) {
      throw redirect({
        to: "/login",
        search: {
          redirect: location.href,
        },
      });
    }
  },
});

function SocialReport() {
  const router = useRouter();
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [category, setCategory] = useState<string | null>(null);
  const { data: categoryData } = useData<Query, any>(
    SOCIAL_REPORT_USE_CASE_CATEGORIES,
    {}
  );
  const { data } = useData<Query, QuerySocialReportUseCaseSharingReportsArgs>(
    SOCIAL_REPORT_USE_CASE_REPORTS,
    {
      variables: {
        filter: {
          category,
        },
        pagination: {
          limit: pagination.pageSize,
          skip: pagination.pageIndex * pagination.pageSize,
        },
      },
    }
  );
  const [getDownloadFile] = useMutation<
    Mutation,
    MutationSocialReportUseCaseDownloadSharingReportArgs
  >(SOCIAL_REPORT_USE_CASE_DOWNLOAD_SHARING_REPORT);

  const downloadFile = async (id: string) => {
    try {
      const res = await getDownloadFile({
        variables: {
          id,
        },
      });
      if (res?.data?.socialReportUseCaseDownloadSharingReport?.downloadUrl) {
        window.open(
          res.data.socialReportUseCaseDownloadSharingReport.downloadUrl,
          "_blank"
        );
        return;
      }
      toast.error("Failed to download file");
    } catch (error: any) {
      toast.error(error?.message ?? "Failed to download file");
    }
  };

  return (
    <>
      <Header>
        <HeaderLeft>
          <Breadcrumb path="Home / Reports" currentPage="Reports" />
        </HeaderLeft>
        <HeaderRight>
          <DefaultHeaderRightCommon />
        </HeaderRight>
      </Header>
      <PageWrapper name="Reports">
        <Container className="grid grid-cols-12 gap-[16px]">
          <div className="col-span-2">
            <ul className="text-base font-medium text-gray-900 bg-white border border-gray-200 rounded-lg overflow-hidden">
              <li
                className={classNames(
                  "w-full px-4 py-2 border-b border-gray-200 hover:bg-[#3b82f6] hover:text-white cursor-pointer",
                  {
                    "bg-[#3b82f6] text-white": !category,
                  }
                )}
                onClick={() => setCategory(null)}
              >
                All
              </li>
              {categoryData?.socialReportUseCaseCategories?.map((item: any) => (
                <li
                  className={classNames(
                    "w-full px-4 py-2 border-b border-gray-200 hover:bg-[#3b82f6] hover:text-white cursor-pointer",
                    {
                      "bg-[#3b82f6] text-white": category === item.id,
                    }
                  )}
                  onClick={() => setCategory(item.id)}
                >
                  {item.icon}&nbsp;{item.name}
                </li>
              ))}
            </ul>
          </div>
          <div className="col-span-10">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-[16px]">
              {data?.socialReportUseCaseSharingReports?.length === 0 && (
                <div className="col-span-full">No Report Found</div>
              )}
              {data?.socialReportUseCaseReports?.map((item) => (
                <Card
                  onClick={() =>
                    router.navigate({
                      to: "/reports/$id",
                      params: { id: item.id },
                    })
                  }
                  key={item.id}
                  className="cursor-pointer"
                >
                  <CardHeader>
                    <CardTitle>{item.name}</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <img
                      className="aspect-[9/12] w-full h-[200px] rounded object-cover"
                      src={item.imageUrl ?? ""}
                      alt=""
                    />
                  </CardBody>
                  <CardFooter>
                    <div className="flex items-center justify-between w-full">
                      <Button
                        onClick={() =>
                          router.navigate({
                            to: "/reports/$id",
                            params: { id: item.id },
                          })
                        }
                        variant="solid"
                        icon="HeroEye"
                        className="w-min"
                      >
                        View
                      </Button>
                      <Button
                        icon="HeroArrowDownTray"
                        onClick={() => downloadFile(item.id)}
                        variant="solid"
                        className="w-min"
                      >
                        Download
                      </Button>
                    </div>
                  </CardFooter>
                </Card>
              ))}
            </div>
            <div className="w-full mt-[16px]">
              <div className="flex overflow-x-auto sm:justify-center">
                <nav className="sticky top-[82px]">
                  <ul className="xs:mt-0 mt-2 inline-flex items-center -space-x-px">
                    <li>
                      <button
                        onClick={() =>
                          setPagination({
                            ...pagination,
                            pageIndex: pagination.pageIndex - 1,
                          })
                        }
                        type="button"
                        className="w-[90px] ml-0 rounded-l-lg border border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white"
                        disabled={pagination.pageIndex === 0}
                      >
                        Previous
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() =>
                          setPagination({
                            ...pagination,
                            pageIndex: pagination.pageIndex - 1,
                          })
                        }
                        type="button"
                        disabled={
                          !data?.socialReportUseCaseSharingReports ||
                          data?.socialReportUseCaseSharingReports?.length <
                            pagination.pageSize
                        }
                        className="w-[90px] rounded-r-lg border border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white"
                      >
                        Next
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </Container>
      </PageWrapper>
    </>
  );
}
