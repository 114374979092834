import FieldWrap from "@/components/form/FieldWrap";
import Input from "@/components/form/Input";
import Validation from "@/components/form/Validation";
import Icon from "@/components/icon/Icon";
import PageWrapper from "@/components/layouts/PageWrapper/PageWrapper";
import Button from "@/components/ui/Button";
import {
  Mutation,
  MutationAuthSignUpWithEmailAndPasswordArgs,
} from "@/gql/graphql";
import { REGISTER_MUTATION } from "@/gql/schema";
import { useMutation } from "@apollo/client";
import { createFileRoute, Link, useRouter } from "@tanstack/react-router";
import classNames from "classnames";
import { useFormik } from "formik";
import { useState } from "react";
import toast from "react-hot-toast";

export const Route = createFileRoute("/register")({
  component: Register,
});

type TValues = {
  name: string;
  username: string;
  password: string;
  confirmPassword: string;
};

function Register() {
  const router = useRouter();
  const [registerMutation, { loading }] = useMutation<
    Mutation,
    MutationAuthSignUpWithEmailAndPasswordArgs
  >(REGISTER_MUTATION);
  const [passwordShowStatus, setPasswordShowStatus] = useState<boolean>(false);
  const [confirmPasswordShowStatus, setConfirmPasswordShowStatus] =
    useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      name: "",
      username: "",
      password: "",
      confirmPassword: "",
    },
    validate: (values: TValues) => {
      const errors: Partial<TValues> = {};

      if (!values.name) {
        errors.name = "Required";
      }

      if (!values.username) {
        errors.username = "Required";
      }

      if (!values.password) {
        errors.password = "Required";
      }

      if (!values.confirmPassword) {
        errors.confirmPassword = "Required";
      }

      if (values.password !== values.confirmPassword) {
        errors.confirmPassword = "Passwords do not match";
      }

      // validate email with regex
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.username)) {
        errors.username = "Invalid email address";
      }

      return errors;
    },
    onSubmit: async (values: TValues, { setFieldError }) => {
      try {
        const res = await registerMutation({
          variables: {
            input: {
              email: values.username,
              password: values.password,
              familyName: values.name,
              givenName: values.name,
            },
          },
        });
        if (res.data?.authSignUpWithEmailAndPassword) {
          toast.success(
            "Register successfully! Please check your email to verify your account"
          );
          router.navigate({
            to: "/login",
          });
          return;
        }
        toast.error("Register failed! Please try again");
      } catch (error: any) {
        setFieldError(
          "password",
          error?.message ?? "Register failed! Please try again"
        );
      }
    },
  });

  return (
    <PageWrapper
      isProtectedRoute={false}
      className="bg-white dark:bg-inherit"
      name="Sign Up"
    >
      <div className="container mx-auto flex h-full items-center justify-center">
        <div className="flex max-w-sm flex-col gap-8 md:w-[400px]">
          <div>{/* <LogoTemplate className='h-12' /> */}</div>
          <div>
            <span className="text-4xl font-semibold">Sign up</span>
          </div>
          <div className="border border-zinc-500/25 dark:border-zinc-500/50" />
          <div>
            <span>Sign up with email address</span>
          </div>
          <form className="flex flex-col gap-4" noValidate>
            <div
              className={classNames({
                "mb-2": !formik.isValid,
              })}
            >
              <Validation
                isValid={formik.isValid}
                isTouched={formik.touched.name}
                invalidFeedback={formik.errors.name}
                validFeedback=""
              >
                <FieldWrap
                  firstSuffix={<Icon icon="HeroUser" className="mx-2" />}
                >
                  <Input
                    dimension="lg"
                    id="name"
                    autoComplete="name"
                    name="name"
                    placeholder="Full name"
                    className="pl-[32px]"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </FieldWrap>
              </Validation>
            </div>
            <div
              className={classNames({
                "mb-2": !formik.isValid,
              })}
            >
              <Validation
                isValid={formik.isValid}
                isTouched={formik.touched.username}
                invalidFeedback={formik.errors.username}
                validFeedback=""
              >
                <FieldWrap
                  firstSuffix={<Icon icon="HeroEnvelope" className="mx-2" />}
                >
                  <Input
                    dimension="lg"
                    id="username"
                    autoComplete="username"
                    name="username"
                    placeholder="Email"
                    className="pl-[32px]"
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </FieldWrap>
              </Validation>
            </div>
            <div
              className={classNames({
                "mb-2": !formik.isValid,
              })}
            >
              <Validation
                isValid={formik.isValid}
                isTouched={formik.touched.password}
                invalidFeedback={formik.errors.password}
                validFeedback=""
              >
                <FieldWrap
                  firstSuffix={<Icon icon="HeroKey" className="mx-2" />}
                  lastSuffix={
                    <Icon
                      className="mx-2 cursor-pointer"
                      icon={passwordShowStatus ? "HeroEyeSlash" : "HeroEye"}
                      onClick={() => {
                        setPasswordShowStatus(!passwordShowStatus);
                      }}
                    />
                  }
                >
                  <Input
                    dimension="lg"
                    type={passwordShowStatus ? "text" : "password"}
                    autoComplete="current-password"
                    id="password"
                    name="password"
                    placeholder="Password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </FieldWrap>
              </Validation>
            </div>
            <div
              className={classNames({
                "mb-2": !formik.isValid,
              })}
            >
              <Validation
                isValid={formik.isValid}
                isTouched={formik.touched.confirmPassword}
                invalidFeedback={formik.errors.confirmPassword}
                validFeedback=""
              >
                <FieldWrap
                  firstSuffix={<Icon icon="HeroKey" className="mx-2" />}
                  lastSuffix={
                    <Icon
                      className="mx-2 cursor-pointer"
                      icon={
                        confirmPasswordShowStatus ? "HeroEyeSlash" : "HeroEye"
                      }
                      onClick={() => {
                        setConfirmPasswordShowStatus(
                          !confirmPasswordShowStatus
                        );
                      }}
                    />
                  }
                >
                  <Input
                    dimension="lg"
                    type={confirmPasswordShowStatus ? "text" : "password"}
                    autoComplete="current-password"
                    id="confirmPassword"
                    name="confirmPassword"
                    placeholder="Confirm password"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </FieldWrap>
              </Validation>
            </div>
            <div>
              <Button
                size="lg"
                variant="solid"
                className="w-full font-semibold"
                onClick={() => formik.handleSubmit()}
                isLoading={loading}
              >
                Sign up
              </Button>
            </div>
          </form>
          <div>
            <span className="flex gap-2 text-sm">
              <span className="text-zinc-400 dark:text-zinc-600">
                Already have an account?
              </span>
              <Link to="/login" className="hover:text-inherit">
                Sign in now
              </Link>
            </span>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
}
