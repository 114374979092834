import Wrapper from "@/components/layouts/Wrapper/Wrapper";
import ContentRouter from "@/components/router/ContentRouter";
import { AuthProvider } from "@/context/authContext";
import { ThemeContextProvider } from "@/context/themeContext";
import { User } from "@/gql/graphql";
import DefaultAsideTemplate from "@/templates/layouts/Asides/DefaultAside.template";
import { createRootRouteWithContext, Outlet } from "@tanstack/react-router";
import { GoogleOAuthProvider } from "@react-oauth/google";

interface RouterContext {
  user: User;
}

export const Route = createRootRouteWithContext<RouterContext>()({
  component: () => (
    <ThemeContextProvider>
      <AuthProvider>
        <div data-component-name="App" className="flex grow flex-col">
          <DefaultAsideTemplate />
          <Wrapper>
            <ContentRouter>
              <GoogleOAuthProvider
                clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}
              >
                <Outlet />
              </GoogleOAuthProvider>
            </ContentRouter>
          </Wrapper>
        </div>
      </AuthProvider>
    </ThemeContextProvider>
  ),
});
