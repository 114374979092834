import UserTemplate from "../../User/User.template";
import { AsideFooter } from "../../../../components/layouts/Aside/Aside";

const AsideFooterPart = () => {
  return (
    <AsideFooter>
      <UserTemplate />
      {/* <DarkModeSwitcherPart /> */}
    </AsideFooter>
  );
};

export default AsideFooterPart;
