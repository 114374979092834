import { useAuthStore } from "@/store/auth";
import Aside, { AsideBody } from "../../../components/layouts/Aside/Aside";
import Nav, { NavItem } from "../../../components/layouts/Navigation/Nav";
import AsideFooterPart from "./_parts/AsideFooter.part";
import AsideHeadPart from "./_parts/AsideHead.part";

const DefaultAsideTemplate = () => {
  const user = useAuthStore((state) => state.user);

  if (!user) return null;
  return (
    <Aside>
      <AsideHeadPart />
      <AsideBody>
        <Nav>
          <NavItem
            id="dashboard"
            to="/"
            text="Dashboard"
            icon="HeroRectangleGroup"
          />
          <NavItem id="Reports" to="/reports" text="Reports" icon="HeroBolt" />
          <NavItem
            id="Sharing Reports"
            to="/sharing-reports"
            text="Sharing Reports"
            icon="HeroShare"
          />
          <NavItem
            id="Your subscription"
            to="/your-subscription"
            text="Your subscription"
            icon="HeroCreditCard"
          />
          <NavItem
            id="Purchased"
            to="/purchased"
            text="Purchased"
            icon="HeroArrowDown"
          />
          <NavItem
            id="BillingHistory"
            to="/billing-history"
            text="Billing History"
            icon="HeroBanknotes"
          />
        </Nav>
      </AsideBody>
      <AsideFooterPart />
    </Aside>
  );
};

export default DefaultAsideTemplate;
