import { authPages } from "@/config/pages.config";
import { useAuthStore } from "@/store/auth";
import { useRouter } from "@tanstack/react-router";
import Icon from "../../../components/icon/Icon";
import {
  NavItem,
  NavSeparator,
} from "../../../components/layouts/Navigation/Nav";
import User from "../../../components/layouts/User/User";
import { useQuery } from "@apollo/client";
import { Query } from "@/gql/graphql";
import { SUBSCRIPTION_USE_CASE_ACTIVE_SUBSCRIPTION } from "@/gql/schema";
import Badge from "@/components/ui/Badge";

const UserTemplate = () => {
  const { data } = useQuery<Query>(SUBSCRIPTION_USE_CASE_ACTIVE_SUBSCRIPTION);
  const isPro = data?.subscriptionUseCaseActiveSubscription?.planId === "pro";
  const { user, setUser, setToken, setRefreshToken } = useAuthStore(
    (state) => state
  );

  const router = useRouter();

  const onLogout = () => {
    setUser(null);
    setToken(null);
    setRefreshToken(null);
    router.navigate({
      to: "/login",
    });
  };

  return (
    <User
      isLoading={false}
      name={user?.email ?? ""}
      nameSuffix={user?.isAdmin && <Icon icon="HeroCheckBadge" color="blue" />}
      position={user?.email ?? ""}
      // src={user?.image?.thumb ?? ''}
      suffix={
        isPro && (
          <Badge color="amber" variant="solid" className="text-xs font-bold">
            PRO
          </Badge>
        )
      }
    >
      <NavSeparator />
      <NavItem {...authPages.profilePage} />
      {/* <NavItem {...appPages.mailAppPages.subPages.inboxPages}>
        <Badge variant="solid" className="leading-none">
          3
        </Badge>
        <NavButton
          icon="HeroPlusCircle"
          title="New Mail"
          onClick={() => {
            router.navigate({
              to: `../${appPages.mailAppPages.subPages.newMailPages.to}`,
            });
          }}
        />
      </NavItem> */}
      <NavItem
        text="Logout"
        icon="HeroArrowRightOnRectangle"
        onClick={() => onLogout()}
      />
    </User>
  );
};

export default UserTemplate;
