import {
  type FacebookGroup,
  Query,
  QuerySocialMediaUseCaseFacebookGroupsArgs,
} from "@/gql/graphql";
import { SOCIAL_MEDIA_USE_CASE_FACEBOOK_GROUPS } from "@/gql/schema";
import { useData } from "@/hooks/useData";
import { useState } from "react";
import { CardBody, CardHeader, CardHeaderChild, CardTitle } from "../ui/Card";
import Button from "../ui/Button";
import Table, { TBody, Td, Th, THead, Tr } from "../ui/Table";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import Icon from "../icon/Icon";
import { Link, useRouter } from "@tanstack/react-router";
import dayjs from "dayjs";

const columnHelper = createColumnHelper<FacebookGroup>();

const columns = [
  columnHelper.accessor("thumbnailUrl", {
    cell: (info) => (
      <Link to={`/facebook-groups/group/${info.row.original.id}`}>
        <img
          className="aspect-[9/12] w-[80px] rounded object-contain"
          src={info.getValue()}
          alt=""
        />
      </Link>
    ),
    header: "Image",
    footer: "Image",
  }),
  columnHelper.accessor("name", {
    cell: (info) => (
      <Link to={`/facebook-groups/group/${info.row.original.id}`}>
        <div className="font-bold text-blue-500">{info.getValue()}</div>
      </Link>
    ),
    header: "Group Name",
    footer: "Group Name",
  }),
  columnHelper.accessor("active", {
    cell: (info) => info.getValue(),
    header: "Active",
    footer: "Active",
  }),
  columnHelper.accessor("creationDate", {
    cell: (info) => dayjs(info.getValue()).format("DD MMM YYYY"),
    header: "Created",
    footer: "Created",
  }),
];

const FacebookGroup = () => {
  const router = useRouter();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { data } = useData<Query, QuerySocialMediaUseCaseFacebookGroupsArgs>(
    SOCIAL_MEDIA_USE_CASE_FACEBOOK_GROUPS,
    {
      variables: {
        pagination: {
          skip: (page - 1) * limit,
          limit: limit,
        },
      },
    }
  );
  const table = useReactTable({
    data: data?.socialMediaUseCaseFacebookGroups ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      pagination: {
        pageIndex: page - 1,
        pageSize: limit,
      },
    },
    onStateChange: (state) => {
      // setPage(state.pagination.pageIndex + 1);
      // setLimit(state.pagination.pageSize);
    },
  });
  return (
    <>
      <CardHeader>
        <CardHeaderChild>
          <CardTitle>Facebook Groups</CardTitle>
        </CardHeaderChild>
        <CardHeaderChild>
          <Button
            color="zinc"
            variant="outline"
            onClick={() => router.navigate({ to: "/facebook-groups" })}
          >
            All Facebook Groups
          </Button>
          {/* <Button icon="HeroCloudArrowDown" variant="solid">
            Click
          </Button> */}
        </CardHeaderChild>
      </CardHeader>
      <CardBody className="overflow-auto">
        <Table className="table-fixed max-md:min-w-[70rem]">
          <THead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th
                    key={header.id}
                    isColumnBorder={false}
                    className="text-left"
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        key={header.id}
                        aria-hidden="true"
                        {...{
                          className: header.column.getCanSort()
                            ? "cursor-pointer select-none flex items-center"
                            : "",
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: (
                            <Icon
                              icon="HeroChevronUp"
                              className="ltr:ml-1.5 rtl:mr-1.5"
                            />
                          ),
                          desc: (
                            <Icon
                              icon="HeroChevronDown"
                              className="ltr:ml-1.5 rtl:mr-1.5"
                            />
                          ),
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    )}
                  </Th>
                ))}
              </Tr>
            ))}
          </THead>
          <TBody>
            {table.getRowModel().rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </TBody>
        </Table>
      </CardBody>
    </>
  );
};

export default FacebookGroup;
