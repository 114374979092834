import Container from "@/components/layouts/Container/Container";
import Header, { HeaderRight } from "@/components/layouts/Header/Header";
import PageWrapper from "@/components/layouts/PageWrapper/PageWrapper";
import Loading from "@/components/loading";
import Card, { CardBody, CardHeader, CardTitle } from "@/components/ui/Card";
import { Mutation, MutationAuthConfirmEmailArgs } from "@/gql/graphql";
import { CONFIRM_EMAIL_MUTATION } from "@/gql/schema";
import DefaultHeaderRightCommon from "@/templates/layouts/Headers/_common/DefaultHeaderRight.common";
import { useMutation } from "@apollo/client";
import {
  createFileRoute,
  useNavigate,
  useSearch,
} from "@tanstack/react-router";
import { useEffect } from "react";

export const Route = createFileRoute("/auth/confirm")({
  component: ConfirmEmail,
});

function ConfirmEmail() {
  const navigate = useNavigate();
  const [confirmEmailMutation, { loading: confirmEmailLoading }] = useMutation<
    Mutation,
    MutationAuthConfirmEmailArgs
  >(CONFIRM_EMAIL_MUTATION);
  // search params
  const searchParams: any = useSearch({ from: Route.id });
  const token = searchParams["token"];
  useEffect(() => {
    if (token) {
      confirmEmailMutation({ variables: { input: { token } } })
        .then(() => {
          setTimeout(() => {
            navigate({ to: "/login" });
          }, 3000);
        })
        .catch((error) => {
          console.error(error);
          setTimeout(() => {
            navigate({ to: "/login" });
          }, 3000);
        });
    }
  }, [token]);
  return (
    <>
      <Header>
        <HeaderRight>
          <DefaultHeaderRightCommon />
        </HeaderRight>
      </Header>
      <PageWrapper name="Email Verification">
        <Container>
          <Card>
            <CardHeader>
              <CardTitle>Email Verification</CardTitle>
            </CardHeader>
            <CardBody>
              <div className="bg-gray-100">
                <div className="bg-white p-6  md:mx-auto">
                  <svg
                    viewBox="0 0 24 24"
                    className="text-green-600 w-16 h-16 mx-auto my-6"
                  >
                    <path
                      fill="currentColor"
                      d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
                    ></path>
                  </svg>
                  {confirmEmailLoading ? (
                    <div className="text-center">
                      <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">
                        Email verifying
                      </h3>
                      <Loading />
                    </div>
                  ) : (
                    <div className="text-center">
                      <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">
                        Email Verified!
                      </h3>
                      <p className="text-gray-600 my-2">
                        You will be redirected to the login page in 3 seconds.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </CardBody>
          </Card>
        </Container>
      </PageWrapper>
    </>
  );
}
