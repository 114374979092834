import client from "@/components/apollo-client";
import ContentAnalytic from "@/components/group/ContentAnalytic";
import TopPost from "@/components/group/TopPost";
import Breadcrumb from "@/components/layouts/Breadcrumb/Breadcrumb";
import Container from "@/components/layouts/Container/Container";
import Header, {
  HeaderLeft,
  HeaderRight,
} from "@/components/layouts/Header/Header";
import PageWrapper from "@/components/layouts/PageWrapper/PageWrapper";
import Button from "@/components/ui/Button";
import Card, {
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/Card";
import {
  Mutation,
  MutationCartUseCaseAddToCartArgs,
  Query,
  QueryProductUseCaseProductByIdsArgs,
  QuerySocialMediaUseCaseFacebookGroupArgs,
  QuerySocialReportUseCaseReportArgs,
} from "@/gql/graphql";
import {
  CART_USE_CASE_ADD_TO_CART,
  CART_USE_CASE_CURRENT_USER_CART,
  PRODUCT_USE_CASE_PRODUCT_BY_IDS,
  REPORT_USE_CASE_REPORT,
  SOCIAL_MEDIA_USE_CASE_FACEBOOK_GROUP,
} from "@/gql/schema";
import DefaultHeaderRightCommon from "@/templates/layouts/Headers/_common/DefaultHeaderRight.common";
import { useMutation, useQuery } from "@apollo/client";
import { createFileRoute, useParams, useRouter } from "@tanstack/react-router";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

export const Route = createFileRoute("/reports/$id")({
  component: ReportDetail,
});

function ReportDetail() {
  const router = useRouter();
  const id = useParams({ from: "/reports/$id" }).id;
  const { data } = useQuery<Query, QuerySocialReportUseCaseReportArgs>(
    REPORT_USE_CASE_REPORT,
    {
      variables: { id },
    }
  );
  const { data: groupData } = useQuery<
    Query,
    QuerySocialMediaUseCaseFacebookGroupArgs
  >(SOCIAL_MEDIA_USE_CASE_FACEBOOK_GROUP, {
    variables: { id: data?.socialReportUseCaseReport?.facebookGroupId ?? "" },
    skip: !data?.socialReportUseCaseReport?.facebookGroupId,
  });
  const { data: productData } = useQuery<
    Query,
    QueryProductUseCaseProductByIdsArgs
  >(PRODUCT_USE_CASE_PRODUCT_BY_IDS, {
    variables: {
      productIds: data?.socialReportUseCaseReport?.productIds ?? [],
    },
    skip: !data?.socialReportUseCaseReport?.productIds,
  });
  const [addToCart, { loading }] = useMutation<
    Mutation,
    MutationCartUseCaseAddToCartArgs
  >(CART_USE_CASE_ADD_TO_CART);
  const onAddToCart = async (productId: string) => {
    const res = await addToCart({
      variables: {
        input: {
          productId,
        },
      },
    });
    if (res.data?.cartUseCaseAddToCart) {
      toast.success("Product added to cart");
      await client.refetchQueries({
        include: [CART_USE_CASE_CURRENT_USER_CART],
      });
    }
  };
  // const statistics = [
  //   {
  //     title: "Total Phone",
  //     color: "#0040ff",
  //     value: data?.socialReportUseCaseReport?.statistics?.totalPhone ?? 0,
  //   },
  //   {
  //     title: "Total Email",
  //     color: "#0040ff",
  //     value: data?.socialReportUseCaseReport?.statistics?.totalEmail ?? 0,
  //   },
  //   {
  //     title: "Total DOB",
  //     color: "#0040ff",
  //     value: data?.socialReportUseCaseReport?.statistics?.totalDob ?? 0,
  //   },
  //   {
  //     title: "Facebook Name",
  //     color: "#0040ff",
  //     value:
  //       data?.socialReportUseCaseReport?.statistics?.totalFacebookName ?? 0,
  //   },
  // ];

  const onCopyToClipboard = () => {
    navigator.clipboard.writeText(window.location.href);
    toast.success("Copied Link to clipboard");
  };
  return (
    <>
      <Header>
        <HeaderLeft>
          <Breadcrumb
            path="Home / Reports"
            currentPage={data?.socialReportUseCaseReport?.name ?? ""}
          />
        </HeaderLeft>
        <HeaderRight>
          <DefaultHeaderRightCommon />
        </HeaderRight>
      </Header>
      <PageWrapper name={groupData?.socialReportUseCaseReport?.name ?? ""}>
        <Container>
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12 flex flex-row items-center justify-between">
              <h1 className="text-[28px] my-4 font-bold">
                {groupData?.socialMediaUseCaseFacebookGroup?.name ?? ""}
              </h1>
              <div className="flex flex-row items-center gap-2">
                <Button
                  icon="HeroEye"
                  onClick={() =>
                    window.open(
                      groupData?.socialMediaUseCaseFacebookGroup?.url ?? "",
                      "_blank"
                    )
                  }
                  variant="solid"
                >
                  Open Facebook
                </Button>
                <Button
                  icon="HeroClipboardDocumentList"
                  onClick={() => onCopyToClipboard()}
                  variant="solid"
                >
                  Copy Link
                </Button>
              </div>
            </div>
          </div>
          <div className="w-full grid grid-cols-12 gap-4 mb-4">
            <div className="col-span-12">
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-12 lg:col-span-6">
                  <div className="grid grid-cols-12 gap-4">
                    <div className="col-span-12">
                      <h1 className="my-4 font-bold">Group information</h1>
                    </div>
                  </div>
                  <Card>
                    <CardBody>
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
                        <div className="col-span-12 md:col-span-2">
                          <label
                            data-component-name="Label"
                            className="mb-2 inline-block w-full cursor-pointer text-[16px] font-bold"
                            htmlFor="id"
                          >
                            Type
                          </label>
                          <p className="text-base peer-disabled:pointer-events-none peer-disabled:opacity-50 uppercase">
                            {groupData?.socialMediaUseCaseFacebookGroup?.type ??
                              ""}
                          </p>
                          <label
                            data-component-name="Label"
                            className="mb-2 inline-block w-full cursor-pointer text-[16px] font-bold"
                            htmlFor="id"
                          >
                            Status
                          </label>
                          <p className="text-base peer-disabled:pointer-events-none peer-disabled:opacity-50">
                            {groupData?.socialMediaUseCaseFacebookGroup
                              ?.active ?? ""}
                          </p>
                          <label
                            data-component-name="Label"
                            className="mb-2 inline-block w-full cursor-pointer text-[16px] font-bold"
                            htmlFor="id"
                          >
                            Description
                          </label>
                          <p className="text-base peer-disabled:pointer-events-none peer-disabled:opacity-50">
                            {groupData?.socialMediaUseCaseFacebookGroup
                              ?.description ?? ""}
                          </p>
                          <label
                            data-component-name="Label"
                            className="mb-2 inline-block w-full cursor-pointer text-[16px] font-bold"
                            htmlFor="id"
                          >
                            Created Date
                          </label>
                          <p className="text-base peer-disabled:pointer-events-none peer-disabled:opacity-50">
                            {dayjs(
                              groupData?.socialMediaUseCaseFacebookGroup
                                ?.creationDate
                            ).format("DD MMM YYYY")}
                          </p>
                        </div>

                        <img
                          src={
                            groupData?.socialMediaUseCaseFacebookGroup
                              ?.coverImageUrl
                          }
                          className="h-[180px] object-contain mb-2"
                        />
                      </div>
                      <label
                        data-component-name="Label"
                        className="mb-2 inline-block w-full cursor-pointer text-[16px] font-bold"
                        htmlFor="id"
                      >
                        Share
                      </label>
                      <div className="flex flex-row items-center gap-2">
                        <FacebookShareButton url={window.location.href}>
                          <FacebookIcon size={32} round />
                        </FacebookShareButton>
                        <LinkedinShareButton url={window.location.href}>
                          <LinkedinIcon size={32} round />
                        </LinkedinShareButton>
                        <WhatsappShareButton url={window.location.href}>
                          <WhatsappIcon size={32} round />
                        </WhatsappShareButton>
                        <EmailShareButton url={window.location.href}>
                          <EmailIcon size={32} round />
                        </EmailShareButton>
                      </div>
                    </CardBody>
                  </Card>
                </div>
                <div className="col-span-12 lg:col-span-6 text-left">
                  <div className="grid grid-cols-12 gap-4">
                    <div className="col-span-12">
                      <h1 className="my-4 font-bold">Member information</h1>
                    </div>
                  </div>
                  <div className="w-full grid grid-cols-2 gap-x-[16px]">
                    {productData?.productUseCaseProductByIds.map((product) => (
                      <Card
                        key={product.id}
                        className="cursor-pointer hover:shadow-md"
                        onClick={() =>
                          router.navigate({
                            to: "/reports/member/$id",
                            params: { id: product.id },
                          })
                        }
                      >
                        <CardHeader>
                          <CardTitle>
                            <div className="flex items-start justify-between gap-2 w-full">
                              <div className="font-medium">{product.name}</div>
                              <span
                                data-component-name="Badge"
                                className="inline-flex items-center justify-center px-2 border-2 rounded-lg border-emerald-500 bg-emerald-500/10 text-emerald-500 border-transparent text-xl font-bold"
                              >
                                ${product.price}
                              </span>
                            </div>
                          </CardTitle>
                        </CardHeader>
                        <CardBody>
                          <div
                            className="text-base"
                            dangerouslySetInnerHTML={{
                              __html: product?.description || "",
                            }}
                          ></div>
                          <div className="text-base">
                            Kind: <b>{product.kind}</b>
                          </div>
                        </CardBody>
                        <CardFooter>
                          <div className="flex items-center justify-between w-full">
                            <Button
                              onClick={() =>
                                router.navigate({
                                  to: "/reports/member/$id",
                                  params: { id: product.id },
                                })
                              }
                              variant="solid"
                              icon="HeroEye"
                              className="w-min"
                            >
                              View
                            </Button>
                            <Button
                              variant="solid"
                              isLoading={loading}
                              icon="HeroShoppingCart"
                              onClick={() => onAddToCart(product.id)}
                            >
                              Add to Cart
                            </Button>
                          </div>
                        </CardFooter>
                      </Card>
                    ))}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-12">
                  <h1 className="text-[28px] my-4 font-bold">
                    Content Analytics
                  </h1>
                </div>
                <div className="col-span-12">
                  <ContentAnalytic
                    id={
                      data?.socialReportUseCaseReport?.contentAnalysisId ?? ""
                    }
                  />
                </div>
                <div className="col-span-12">
                  <h1 className="text-[28px] my-4 font-bold">Top Posts</h1>
                </div>
                <div className="col-span-12">
                  <TopPost
                    id={data?.socialReportUseCaseReport?.facebookGroupId ?? ""}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="w-full grid grid-cols-12 gap-4 mb-4">
            {data?.socialReportUseCaseReport?.statistics && (
              <div className="col-span-12 grid grid-cols-3 md:grid-cols-6 gap-x-[16px]">
                {statistics.map((item) => (
                  <Card
                    className="border-[1px] rounded-[16px] shadow-md bg-white"
                    style={{ borderColor: item.color }}
                  >
                    <CardBody>
                      <div className="h-[180px] flex flex-col items-center justify-between">
                        <p className="text-[14px] text-black font-bold">
                          {item.title}
                        </p>
                        <h5
                          className="text-[42px] mt-[32px] font-bold text-center"
                          style={{ color: item.color }}
                        >
                          {item.value}
                        </h5>
                        <div className="h-[32px]" />
                      </div>
                    </CardBody>
                  </Card>
                ))}
              </div>
            )}
          </div> */}
        </Container>
      </PageWrapper>
    </>
  );
}

export default ReportDetail;
