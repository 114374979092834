import { Link } from "@tanstack/react-router";
import Icon from "../../../../components/icon/Icon";
import Visible from "../../../../components/utils/Visible";
import useAsideStatus from "../../../../hooks/useAsideStatus";
import LogoTemplate from "../../Logo/Logo.template";

const LogoAndAsideTogglePart = () => {
  const { asideStatus, setAsideStatus } = useAsideStatus();
  return (
    <>
      <Visible is={asideStatus}>
        <Link to="/" aria-label="Logo">
          <img
            src="/images/sharing-report-logo-full-remove-bg.png"
            alt="Logo"
            className="h-12"
          />
        </Link>
      </Visible>
      <button
        type="button"
        aria-label="Toggle Aside Menu"
        onClick={() => setAsideStatus(!asideStatus)}
        className="flex h-12 w-12 items-center justify-center"
      >
        <Icon
          icon={asideStatus ? "HeroBars3BottomLeft" : "HeroBars3"}
          className="text-2xl"
        />
      </button>
    </>
  );
};

export default LogoAndAsideTogglePart;
