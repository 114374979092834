import client from "@/components/apollo-client";
import { ApolloProvider } from "@apollo/client";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { Toaster } from "react-hot-toast";
// Import the generated route tree
// import * as Sentry from "@sentry/react";

//global styles
import "./styles/index.css";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import "./styles/vendors.css";

// if (process.env.NODE_ENV === "production") {
// 	Sentry.init({
// 		dsn: "https://f602d076ebab539275f136d46df615cc@o316168.ingest.us.sentry.io/4507593379545088",
// 		integrations: [
// 			Sentry.browserTracingIntegration(),
// 			Sentry.replayIntegration(),
// 		],
// 		// Performance Monitoring
// 		tracesSampleRate: 1.0, //  Capture 100% of the transactions
// 		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
// 		tracePropagationTargets: ["localhost"],
// 		// Session Replay
// 		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
// 		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// 	});
// }

import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";
import InnerApp from "./router";

if (process.env.NODE_ENV === "development") {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

const queryClient = new QueryClient();

// Render the app
const rootElement = document.getElementById("root")!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <ApolloProvider client={client}>
        <QueryClientProvider client={queryClient}>
          <InnerApp />
          <Toaster />
        </QueryClientProvider>
      </ApolloProvider>
    </StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
