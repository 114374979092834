import Balance1Partial from "@/components/dashboard/Balance1.partial";
import Balance2Partial from "@/components/dashboard/Balance2.partial";
import Balance3Partial from "@/components/dashboard/Balance3.partial";
import Balance4Partial from "@/components/dashboard/Balance4.partial";
import ChartPartial from "@/components/dashboard/Chart.partial";
import FacebookGroup from "@/components/dashboard/FacebookGroup";
import PeriodButtonsPartial from "@/components/dashboard/PeriodButtons.partial";
import Breadcrumb from "@/components/layouts/Breadcrumb/Breadcrumb";
import Container from "@/components/layouts/Container/Container";
import Header, {
  HeaderLeft,
  HeaderRight,
} from "@/components/layouts/Header/Header";
import PageWrapper from "@/components/layouts/PageWrapper/PageWrapper";
import Subheader, {
  SubheaderLeft,
  SubheaderRight,
} from "@/components/layouts/Subheader/Subheader";
import Button from "@/components/ui/Button";
import Card from "@/components/ui/Card";
import Dropdown, {
  DropdownMenu,
  DropdownToggle,
} from "@/components/ui/Dropdown";
import colors from "@/config/color";
import themeConfig from "@/config/theme.config";
import { PERIOD, Range, TPeriod } from "@/constants/home";
import DefaultHeaderRightCommon from "@/templates/layouts/Headers/_common/DefaultHeaderRight.common";
import { createFileRoute, redirect } from "@tanstack/react-router";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";

export const Route = createFileRoute("/")({
  component: Index,
  beforeLoad: ({ context, location }) => {
    if (!context.user) {
      throw redirect({
        to: "/login",
        search: {
          redirect: location.href,
        },
      });
    }
  },
});

function Index() {
  const [activeTab, setActiveTab] = useState<TPeriod>(PERIOD.DAY);

  const [selectedDate, setSelectedDate] = useState<Range[]>([
    {
      startDate: dayjs().startOf("week").add(-1, "week").toDate(),
      endDate: dayjs().endOf("week").toDate(),
      key: "selection",
    },
  ]);

  useEffect(() => {
    if (activeTab === PERIOD.DAY) {
      setSelectedDate([
        {
          startDate: dayjs().startOf("day").toDate(),
          endDate: dayjs().endOf("day").toDate(),
          key: "selection",
        },
      ]);
    }
    if (activeTab === PERIOD.WEEK) {
      setSelectedDate([
        {
          startDate: dayjs().startOf("week").toDate(),
          endDate: dayjs().endOf("week").toDate(),
          key: "selection",
        },
      ]);
    }
    if (activeTab === PERIOD.MONTH) {
      setSelectedDate([
        {
          startDate: dayjs().startOf("month").toDate(),
          endDate: dayjs().endOf("month").toDate(),
          key: "selection",
        },
      ]);
    }
    return () => {};
  }, [activeTab]);

  useEffect(() => {
    const selectedStart = dayjs(selectedDate[0].startDate).format("LL");
    const selectedEnd = dayjs(selectedDate[0].endDate).format("LL");

    if (
      selectedStart === dayjs().startOf("day").format("LL") &&
      selectedEnd === dayjs().endOf("day").format("LL")
    ) {
      setActiveTab(PERIOD.DAY);
    }
    if (
      selectedStart === dayjs().startOf("week").format("LL") &&
      selectedEnd === dayjs().endOf("week").format("LL")
    ) {
      setActiveTab(PERIOD.WEEK);
    }
    if (
      selectedStart === dayjs().startOf("month").format("LL") &&
      selectedEnd === dayjs().endOf("month").format("LL")
    ) {
      setActiveTab(PERIOD.MONTH);
    }
    return () => {};
  }, [selectedDate]);

  return (
    <>
      <Header>
        <HeaderLeft>
          <Breadcrumb path="Pages / Dashboard" currentPage="Dashboard" />
        </HeaderLeft>
        <HeaderRight>
          <DefaultHeaderRightCommon />
        </HeaderRight>
      </Header>
      <PageWrapper name="Sales Dashboard">
        {/* <Subheader>
          <SubheaderLeft>
            <PeriodButtonsPartial
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </SubheaderLeft>
          <SubheaderRight>
            <Dropdown>
              <DropdownToggle>
                <Button icon="HeroCalendarDays">
                  {activeTab === PERIOD.DAY && dayjs().format("LL")}
                  {activeTab === PERIOD.WEEK &&
                    `${dayjs()
                      .startOf("week")

                      .format("MMMM D")} - ${dayjs()
                      .endOf("week")

                      .format("MMMM D, YYYY")}`}
                  {activeTab === PERIOD.MONTH &&
                    dayjs()
                      .startOf("month")

                      .format("MMMM, YYYY")}
                </Button>
              </DropdownToggle>
              <DropdownMenu className="!p-0">
                <DateRangePicker
                  onChange={(item) => setSelectedDate([item.selection])}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  ranges={selectedDate}
                  direction="horizontal"
                  rangeColors={[
                    colors[themeConfig.themeColor][themeConfig.themeColorShade],
                    colors.emerald[themeConfig.themeColorShade],
                    colors.amber[themeConfig.themeColorShade],
                  ]}
                />
              </DropdownMenu>
            </Dropdown>
          </SubheaderRight>
        </Subheader> */}
        <Container>
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12 sm:col-span-6 lg:col-span-3">
              <Balance1Partial activeTab={activeTab} />
            </div>
            <div className="col-span-12 sm:col-span-6 lg:col-span-3">
              <Balance2Partial activeTab={activeTab} />
            </div>
            <div className="col-span-12 sm:col-span-6 lg:col-span-3">
              <Balance3Partial activeTab={activeTab} />
            </div>
            <div className="col-span-12 sm:col-span-6 lg:col-span-3">
              <Balance4Partial activeTab={activeTab} />
            </div>
            <div className="col-span-12">
              <Card className="h-full">
                <FacebookGroup />
              </Card>
            </div>
            {/* <div className="col-span-12">
              <ChartPartial />
            </div> */}
          </div>
        </Container>
      </PageWrapper>
    </>
  );
}
