import Dropdown, {
  DropdownMenu,
  DropdownToggle,
} from "../../../../components/ui/Dropdown";
import Button from "../../../../components/ui/Button";
import { useQuery, useMutation } from "@apollo/client";
import {
  CART_USE_CASE_CHECKOUT_CART,
  CART_USE_CASE_CURRENT_USER_CART,
  CART_USE_CASE_REMOVE_FROM_CART,
  PRODUCT_USE_CASE_PRODUCT_BY_IDS,
} from "@/gql/schema";
import {
  Mutation,
  MutationCartUseCaseRemoveFromCartArgs,
  Query,
  QueryProductUseCaseProductByIdsArgs,
} from "@/gql/graphql";
import { useMemo } from "react";
import toast from "react-hot-toast";
import { useAuthStore } from "@/store/auth";
import { useRouter } from "@tanstack/react-router";

const CartPartial = () => {
  const router = useRouter();
  const { user } = useAuthStore((state) => state);
  const { data, refetch } = useQuery<Query>(CART_USE_CASE_CURRENT_USER_CART);
  const { data: productData } = useQuery<
    Query,
    QueryProductUseCaseProductByIdsArgs
  >(PRODUCT_USE_CASE_PRODUCT_BY_IDS, {
    variables: {
      productIds:
        data?.cartUseCaseCurrentUserCart?.cartLineItems?.map(
          (cartLineItem) => cartLineItem.productId
        ) ?? [],
    },
    skip: !data?.cartUseCaseCurrentUserCart?.cartLineItems,
  });

  const [removeFromCart, { loading }] = useMutation<
    Mutation,
    MutationCartUseCaseRemoveFromCartArgs
  >(CART_USE_CASE_REMOVE_FROM_CART);
  const [checkoutCart] = useMutation<Mutation>(CART_USE_CASE_CHECKOUT_CART);

  const handleRemove = async (productId: string) => {
    const res = await removeFromCart({
      variables: {
        input: {
          productId,
        },
      },
    });
    if (res.data?.cartUseCaseRemoveFromCart) {
      toast.success("Product removed from cart");
      await refetch();
    }
  };

  const cartData = useMemo(() => {
    return data?.cartUseCaseCurrentUserCart?.cartLineItems?.map(
      (cartLineItem) => {
        const product = productData?.productUseCaseProductByIds?.find(
          (product) => product?.id === cartLineItem.productId
        );
        return {
          ...cartLineItem,
          product,
        };
      }
    );
  }, [data, productData]);

  const handleCheckout = async () => {
    const res = await checkoutCart();
    if (res.data?.cartUseCaseCheckoutCart) {
      window.location.href = res.data.cartUseCaseCheckoutCart.sessionUrl;
      return;
    }
    toast.error("Failed to checkout");
  };
  const cartTitle = useMemo(() => {
    if (!cartData?.length) return "Your cart";
    if (cartData?.length === 1) return "Your cart (1 item)";
    return `Your cart (${cartData?.length} items)`;
  }, [cartData]);

  if (!user) {
    return (
      <Button
        icon="HeroUser"
        variant="solid"
        onClick={() => router.navigate({ to: "/login" })}
        aria-label="Cart"
      >
        Login/Register
      </Button>
    );
  }
  return (
    <div className="relative">
      <Dropdown>
        <DropdownToggle hasIcon={false}>
          <Button variant="solid" icon="HeroShoppingCart" aria-label="Cart">
            {cartTitle}
          </Button>
        </DropdownToggle>
        <DropdownMenu
          placement="bottom-end"
          onChange={() => refetch()}
          className="flex flex-col flex-wrap divide-y divide-dashed divide-zinc-500/50 p-4 [&>*]:py-4 min-w-[200px]"
        >
          <div className="flex items-center justify-between">
            <h4 className="text-xl font-bold">Your Cart</h4>
            {cartData && cartData?.length > 0 && (
              <Button
                variant="solid"
                isLoading={loading}
                onClick={handleCheckout}
              >
                Checkout
              </Button>
            )}
          </div>
          {!cartData?.length && (
            <div className="text-center text-base text-black">
              No items in cart
            </div>
          )}
          {cartData?.map((cartLineItem) => (
            <div
              key={cartLineItem.productId}
              className="flex min-w-[24rem] gap-2"
            >
              <div className="grow-0">
                <div className="flex gap-2 font-bold">
                  {cartLineItem.product?.name}
                </div>
                <div className="flex w-[18rem] gap-2 text-zinc-500">
                  <span className="truncate">Qty: {cartLineItem.quantity}</span>
                </div>
              </div>
              <div className="relative flex flex-shrink-0 items-center">
                <span
                  data-component-name="Badge"
                  className="inline-flex items-center justify-center px-2 border-2 rounded-lg border-emerald-500 bg-emerald-500/10 text-emerald-500 border-transparent text-xl font-bold"
                >
                  ${cartLineItem.price}
                </span>
              </div>
              <Button
                icon="HeroTrash"
                aria-label="Remove"
                isLoading={loading}
                onClick={() => handleRemove(cartLineItem.productId)}
              />
            </div>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default CartPartial;
