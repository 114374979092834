export const moneyFormat = (value: string | number) => {
  if (!value) return 0;
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const fixFloat = (value: number) => {
  if (!value) return 0;
  return parseFloat(value.toPrecision(15));
};

export const lastString = (str: string, count: number) => {
  return str.slice(str.length - count);
};

export const calculateUnits = (amount: number, decimals: number) => {
  return BigInt(amount * Math.pow(10, decimals)).toString();
};

export const parseUnits = (amount: string, decimals: number) => {
  return parseFloat(amount) / Math.pow(10, decimals);
};

export const upperFirst = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const toggleBgAndOverflow = () => {
  // set bg of body to transparent and overflow auto
  document.body.style.backgroundColor = "transparent";
  document.body.style.overflow = "auto";
  setTimeout(() => {
    document.body.style.backgroundColor = "#212121";
    document.body.style.overflow = "hidden";
  }, 100);
};
