import {
  FacebookGroupPost,
  Query,
  QuerySocialMediaUseCaseFacebookGroupPostsArgs,
} from "@/gql/graphql";
import { SOCIAL_MEDIA_USE_CASE_FACEBOOK_GROUP_POSTS } from "@/gql/schema";
import { useQuery } from "@apollo/client";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import Card, { CardBody } from "../ui/Card";
import Table, { TBody, Td, Th, THead, Tr } from "../ui/Table";
import Icon from "../icon/Icon";
import { TableCardFooterTemplate } from "@/templates/common/TableParts.template";
import { useMemo, useState } from "react";

const columnHelper = createColumnHelper<FacebookGroupPost>();

const columns = [
  columnHelper.accessor("id", {
    cell: (info) => (
      <a
        target="_blank"
        href={`https://facebook.com/${info.getValue()}`}
        className="text-blue-500"
      >
        {info.getValue()}
      </a>
    ),
    header: "ID",
    footer: "ID",
  }),
  columnHelper.accessor("content", {
    cell: (info) => (
      <div className="max-h-[80px] overflow-hidden">{info.getValue()}</div>
    ),
    header: "Content",
    footer: "Content",
  }),
  columnHelper.accessor("authorId", {
    cell: (info) => (
      <a
        target="_blank"
        href={`https://facebook.com/${info.getValue()}`}
        className="text-blue-500"
      >
        {info.getValue()}
      </a>
    ),
    header: "Author",
    footer: "Author",
  }),
  columnHelper.accessor("comments", {
    cell: (info) => info.getValue(),
    header: "Comments",
    footer: "Comments",
  }),
  columnHelper.accessor("likes", {
    cell: (info) => info.getValue(),
    header: "Likes",
    footer: "Likes",
  }),
  columnHelper.accessor("shares", {
    cell: (info) => info.getValue(),
    header: "Shares",
    footer: "Shares",
  }),
];

const TopPost = ({ id }: { id: string }) => {
  const { data } = useQuery<
    Query,
    QuerySocialMediaUseCaseFacebookGroupPostsArgs
  >(SOCIAL_MEDIA_USE_CASE_FACEBOOK_GROUP_POSTS, {
    variables: { facebookGroupId: id },
    skip: !id,
  });
  const [pagination, setPagination] = useState({
    pageIndex: 0, //initial page index
    pageSize: 10, //default page size
  });
  const dataTable = useMemo(() => {
    return data?.socialMediaUseCaseFacebookGroupPosts ?? [];
  }, [data]);
  const table = useReactTable({
    data: dataTable.slice(
      pagination.pageIndex * pagination.pageSize,
      (pagination.pageIndex + 1) * pagination.pageSize
    ),
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    rowCount: data?.socialMediaUseCaseFacebookGroupPosts?.length,
  });
  if (!data?.socialMediaUseCaseFacebookGroupPosts) return null;
  return (
    <div className="w-full">
      <Card className="h-full">
        <CardBody className="overflow-auto">
          <Table className="table-fixed max-md:min-w-[70rem]">
            <THead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Th
                      key={header.id}
                      isColumnBorder={false}
                      className="text-left"
                    >
                      {header.isPlaceholder ? null : (
                        <div
                          key={header.id}
                          aria-hidden="true"
                          {...{
                            className: header.column.getCanSort()
                              ? "cursor-pointer select-none flex items-center"
                              : "",
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: (
                              <Icon
                                icon="HeroChevronUp"
                                className="ltr:ml-1.5 rtl:mr-1.5"
                              />
                            ),
                            desc: (
                              <Icon
                                icon="HeroChevronDown"
                                className="ltr:ml-1.5 rtl:mr-1.5"
                              />
                            ),
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      )}
                    </Th>
                  ))}
                </Tr>
              ))}
            </THead>
            <TBody>
              {table.getRowModel().rows.map((row) => (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <Td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  ))}
                </Tr>
              ))}
            </TBody>
          </Table>
          <div className="w-full mt-[8px]">
            <TableCardFooterTemplate table={table} />
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default TopPost;
