import {
  Query,
  QuerySocialReportUseCaseContentAnalysisArgs,
} from "@/gql/graphql";
import { SOCIAL_REPORT_USE_CASE_CONTENT_ANALYSIS } from "@/gql/schema";
import { useQuery } from "@apollo/client";
import Card, { CardBody } from "../ui/Card";
import ReactApexChart from "react-apexcharts";
import { upperFirst } from "@/utils/string";

const ContentAnalytic = ({ id }: { id: string }) => {
  const { data } = useQuery<Query, QuerySocialReportUseCaseContentAnalysisArgs>(
    SOCIAL_REPORT_USE_CASE_CONTENT_ANALYSIS,
    {
      variables: { id },
      skip: !id,
    }
  );
  const blocks = [
    {
      title: "Post Analytics",
      color: "#0040ff",
      description: "Post",
      value: data?.socialReportUseCaseContentAnalysis?.postAnalysis ?? 0,
    },
    {
      title: "Total Interactions",
      color: "#1a73e8",
      description: "Interaction",
      value: data?.socialReportUseCaseContentAnalysis?.totalInteractions ?? 0,
    },
    {
      title: "Avg Interaction",
      color: "#1a73e8",
      description: "Interaction",
      value: data?.socialReportUseCaseContentAnalysis?.avgInteractions ?? 0,
    },
    {
      title: "Total Likes",
      color: "#0e9d58",
      description: "Likes",
      value: data?.socialReportUseCaseContentAnalysis?.totalLikes ?? 0,
    },
    {
      title: "Total Comments",
      color: "#0e9d58",
      description: "Comments",
      value: data?.socialReportUseCaseContentAnalysis?.totalComments ?? 0,
    },
    {
      title: "Total Shares",
      color: "#0e9d58",
      description: "Shares",
      value: data?.socialReportUseCaseContentAnalysis?.totalShares ?? 0,
    },
  ];
  const chartSeries: ApexCharts.ApexOptions["series"] = [
    {
      data:
        data?.socialReportUseCaseContentAnalysis?.mostKeywords
          ?.filter((i) => i.value > 1)
          ?.map((i) => ({
            x: `${upperFirst(i.key)}: ${Math.round(i.percentage ?? 0)}%`,
            y: Math.round(i.percentage ?? 0),
          })) || [],
    },
  ];
  const chartOption: ApexCharts.ApexOptions = {
    legend: {
      show: false,
    },
    tooltip: {
      enabled: true,
      custom: function (options) {
        return (
          '<div class="p-[8px] border-[1px] rounded-[8px] bg-white border-[#0040ff] shadow-md">' +
          "<span>" +
          options.w.globals.categoryLabels[options.dataPointIndex] +
          "</span>" +
          "</div>"
        );
      },
    },
    chart: {
      height: 350,
      type: "treemap",
    },
    title: {
      text: "Most keywords in Group",
    },
  };
  if (!data?.socialReportUseCaseContentAnalysis) return null;
  return (
    <div>
      <div className="grid grid-cols-3 md:grid-cols-6 gap-x-[16px]">
        {blocks.map((item) => (
          <Card
            className="border-[1px] rounded-[16px] shadow-md bg-white hover:shadow-lg"
            style={{ borderColor: item.color }}
          >
            <CardBody>
              <div className="h-[180px] flex flex-col items-center justify-between">
                <p className="text-[14px] text-black font-bold">{item.title}</p>
                <h5
                  className="text-[42px] mt-[32px] font-bold text-center"
                  style={{ color: item.color }}
                >
                  {item.value}
                  <h4
                    className="text-[20px] mt-[16px] font-medium text-center"
                    style={{ color: item.color }}
                  >
                    {item.description}
                  </h4>
                </h5>
                <div className="h-[32px]" />
              </div>
            </CardBody>
          </Card>
        ))}
      </div>
      <div className="w-full mt-[16px]">
        <div className="border-[1px] rounded-[16px] shadow-md bg-white border-[#0040ff] p-[8px]">
          <ReactApexChart
            options={chartOption}
            series={chartSeries}
            type="treemap"
            height={350}
          />
        </div>
      </div>
    </div>
  );
};

export default ContentAnalytic;
