import {
  Audience,
  Query,
  QueryProductUseCaseSampleAudienceArgs,
} from "@/gql/graphql";
import { PRODUCT_USE_CASE_SAMPLE_AUDIENCE } from "@/gql/schema";
import { useQuery } from "@apollo/client";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import Icon from "../icon/Icon";
import Card, { CardBody } from "../ui/Card";
import Table, { TBody, Td, Th, THead, Tr } from "../ui/Table";
import { useAuthStore } from "@/store/auth";
import { Link, useRouter } from "@tanstack/react-router";
import toast from "react-hot-toast";

const columnHelper = createColumnHelper<Audience>();

const columns = [
  columnHelper.accessor("facebookUid", {
    cell: (info) => (
      <a
        target="_blank"
        href={`https://facebook.com/${info.getValue()}`}
        className="text-blue-500"
      >
        {info.row.original.facebookName}
      </a>
    ),
    header: "ID",
    footer: "ID",
  }),
  columnHelper.accessor("email", {
    cell: (info) => <div className="">{info.getValue()}</div>,
    header: "Email",
    footer: "Email",
  }),
  columnHelper.accessor("phone", {
    cell: (info) => info.getValue(),
    header: "Phone",
    footer: "Phone",
  }),
  columnHelper.accessor("dob", {
    cell: (info) => info.getValue(),
    header: "DOB",
    footer: "DOB",
  }),
  columnHelper.accessor("gender", {
    cell: (info) => info.getValue(),
    header: "Gender",
    footer: "Gender",
  }),
  columnHelper.accessor("location", {
    cell: (info) => info.getValue(),
    header: "Location",
    footer: "Location",
  }),
  columnHelper.accessor("friends", {
    cell: (info) => info.getValue(),
    header: "Friends",
    footer: "Friends",
  }),
];

const MemberData = ({ id }: { id: string }) => {
  const { user } = useAuthStore();
  const router = useRouter();
  const checkUser = () => {
    if (!user) {
      return router.navigate({ to: "/login" });
    }
    toast.error("Please upgrade to PRO plan to view this data");
    return router.navigate({ to: "/your-subscription" });
  };
  const columns_normal = [
    columnHelper.accessor("facebookUid", {
      cell: (info) => (
        <a
          target="_blank"
          href={`https://facebook.com/${info.getValue()}`}
          className="text-blue-500"
        >
          {info.row.original.facebookName}
        </a>
      ),
      header: "ID",
      footer: "ID",
    }),
    columnHelper.accessor("email", {
      cell: () => (
        <div
          className="text-blue-500 cursor-pointer"
          onClick={() => checkUser()}
        >
          View
        </div>
      ),
      header: "Email",
      footer: "Email",
    }),
    columnHelper.accessor("phone", {
      cell: () => (
        <div
          className="text-blue-500 cursor-pointer"
          onClick={() => checkUser()}
        >
          View
        </div>
      ),
      header: "Phone",
      footer: "Phone",
    }),
    columnHelper.accessor("dob", {
      cell: (info) => info.getValue(),
      header: "DOB",
      footer: "DOB",
    }),
    columnHelper.accessor("gender", {
      cell: (info) => info.getValue(),
      header: "Gender",
      footer: "Gender",
    }),
    columnHelper.accessor("location", {
      cell: (info) => info.getValue(),
      header: "Location",
      footer: "Location",
    }),
    columnHelper.accessor("friends", {
      cell: (info) => info.getValue(),
      header: "Friends",
      footer: "Friends",
    }),
  ];
  const { data } = useQuery<Query, QueryProductUseCaseSampleAudienceArgs>(
    PRODUCT_USE_CASE_SAMPLE_AUDIENCE,
    {
      variables: { fileId: id },
      skip: !id,
    }
  );
  const table = useReactTable({
    data: data?.productUseCaseSampleAudience ?? [],
    columns: user?.planId === "pro" ? columns : columns_normal,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });
  if (!data?.productUseCaseSampleAudience) return null;
  return (
    <div className="w-full">
      <Card className="h-full">
        <CardBody className="overflow-auto">
          <Table className="table-fixed max-md:min-w-[70rem]">
            <THead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Th
                      key={header.id}
                      isColumnBorder={false}
                      className="text-left"
                    >
                      {header.isPlaceholder ? null : (
                        <div
                          key={header.id}
                          aria-hidden="true"
                          {...{
                            className: header.column.getCanSort()
                              ? "cursor-pointer select-none flex items-center"
                              : "",
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: (
                              <Icon
                                icon="HeroChevronUp"
                                className="ltr:ml-1.5 rtl:mr-1.5"
                              />
                            ),
                            desc: (
                              <Icon
                                icon="HeroChevronDown"
                                className="ltr:ml-1.5 rtl:mr-1.5"
                              />
                            ),
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      )}
                    </Th>
                  ))}
                </Tr>
              ))}
            </THead>
            <TBody>
              {table.getRowModel().rows.map((row) => (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <Td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  ))}
                </Tr>
              ))}
            </TBody>
          </Table>
        </CardBody>
      </Card>
    </div>
  );
};

export default MemberData;
