import client from "@/components/apollo-client";
import Breadcrumb from "@/components/layouts/Breadcrumb/Breadcrumb";
import Container from "@/components/layouts/Container/Container";
import Header, {
  HeaderLeft,
  HeaderRight,
} from "@/components/layouts/Header/Header";
import PageWrapper from "@/components/layouts/PageWrapper/PageWrapper";
import MemberData from "@/components/product/Member";
import MemberAnalytic from "@/components/product/MemberAnalytic";
import Button from "@/components/ui/Button";
import Card, { CardBody } from "@/components/ui/Card";
import {
  Mutation,
  MutationCartUseCaseAddToCartArgs,
  Query,
  QueryProductUseCaseDownloadPurchasedFileArgs,
  QueryProductUseCaseProductArgs,
} from "@/gql/graphql";
import {
  CART_USE_CASE_ADD_TO_CART,
  CART_USE_CASE_CURRENT_USER_CART,
  PRODUCT_USE_CASE_DOWNLOAD_FILE,
  PRODUCT_USE_CASE_PRODUCT,
} from "@/gql/schema";
import DefaultHeaderRightCommon from "@/templates/layouts/Headers/_common/DefaultHeaderRight.common";
import { useMutation, useQuery } from "@apollo/client";
import { createFileRoute, useParams } from "@tanstack/react-router";
import toast from "react-hot-toast";

export const Route = createFileRoute("/reports/member/$id")({
  component: ProductDetail,
});

function ProductDetail() {
  const id = useParams({ from: "/reports/member/$id" }).id;
  const { data } = useQuery<Query, QueryProductUseCaseProductArgs>(
    PRODUCT_USE_CASE_PRODUCT,
    {
      variables: {
        productId: id,
      },
    }
  );
  const { data: downloadFileData, error: downloadFileError } = useQuery<
    Query,
    QueryProductUseCaseDownloadPurchasedFileArgs
  >(PRODUCT_USE_CASE_DOWNLOAD_FILE, {
    variables: {
      productId: id,
    },
  });
  const [addToCart, { loading }] = useMutation<
    Mutation,
    MutationCartUseCaseAddToCartArgs
  >(CART_USE_CASE_ADD_TO_CART);
  const onAddToCart = async (productId: string) => {
    const res = await addToCart({
      variables: {
        input: {
          productId,
        },
      },
    });
    if (res.data?.cartUseCaseAddToCart) {
      toast.success("Product added to cart");
      await client.refetchQueries({
        include: [CART_USE_CASE_CURRENT_USER_CART],
      });
    }
  };

  const onDownloadReport = async () => {
    if (downloadFileData?.productUseCaseDownloadPurchasedFile?.downloadUrl) {
      window.open(
        downloadFileData?.productUseCaseDownloadPurchasedFile?.downloadUrl,
        "_blank"
      );
      return;
    }
    if (!downloadFileData?.productUseCaseDownloadPurchasedFile?.isPurchased) {
      toast.error("You haven't purchased this product! Please purchase first");
      return;
    }
    toast.error(downloadFileError?.message ?? "No file found");
  };
  return (
    <>
      <Header>
        <HeaderLeft>
          <Breadcrumb
            path="Home / Reports"
            currentPage={data?.productUseCaseProduct?.name ?? ""}
          />
        </HeaderLeft>
        <HeaderRight>
          <DefaultHeaderRightCommon />
        </HeaderRight>
      </Header>
      <PageWrapper name={data?.productUseCaseProduct?.name ?? ""}>
        <Container>
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12 flex flex-row items-center justify-between">
              <h1 className="text-[28px] my-4 font-bold">
                {data?.productUseCaseProduct?.name ?? ""}
              </h1>
              <div className="flex flex-row items-center gap-4">
                {downloadFileData?.productUseCaseDownloadPurchasedFile
                  ?.isPurchased ? (
                  <Button
                    icon="HeroArrowDownTray"
                    onClick={() => onDownloadReport()}
                    variant="solid"
                    className="whitespace-nowrap"
                  >
                    Download Report
                  </Button>
                ) : (
                  <Button
                    icon="HeroShoppingCart"
                    onClick={() => onAddToCart(id)}
                    isLoading={loading}
                    variant="solid"
                    className="whitespace-nowrap"
                  >
                    Add to Cart
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className="w-full grid grid-cols-12 gap-4 mb-4">
            <div className="col-span-12">
              <Card>
                <CardBody>
                  <div className="grid grid-cols-12 gap-4">
                    <div className="col-span-12">
                      <label
                        data-component-name="Label"
                        className="mb-2 inline-block w-full cursor-pointer text-[16px] font-bold"
                        htmlFor="id"
                      >
                        Kind
                      </label>
                      <p className="text-base peer-disabled:pointer-events-none peer-disabled:opacity-50 uppercase">
                        {data?.productUseCaseProduct?.kind ?? ""}
                      </p>
                      <label
                        data-component-name="Label"
                        className="mb-2 inline-block w-full cursor-pointer text-[16px] font-bold"
                        htmlFor="id"
                      >
                        Description
                      </label>
                      <p
                        className="text-base peer-disabled:pointer-events-none peer-disabled:opacity-50"
                        dangerouslySetInnerHTML={{
                          __html:
                            data?.productUseCaseProduct?.description ?? "",
                        }}
                      ></p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
          <div className="w-full">
            <MemberAnalytic
              id={data?.productUseCaseProduct?.memberAnalysisId ?? ""}
            />
          </div>
          <div className="w-full mt-[16px]">
            <MemberData id={data?.productUseCaseProduct?.fileId ?? ""} />
          </div>
        </Container>
      </PageWrapper>
    </>
  );
}

export default ProductDetail;
