import { LOGIN_WITH_REFRESH_TOKEN } from "@/gql/schema";
import { useAuthStore } from "@/store/auth";
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  Operation,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

const authLink = setContext((_, { headers }) => {
  const token = useAuthStore.getState().token;
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const generateRefreshTokenLinkOnUnauthError = ({
  refreshTokenPathName,
  refreshTokenRequestFunc,
}: {
  refreshTokenPathName: string;
  refreshTokenRequestFunc: () => Promise<void>;
}) => {
  return [
    onError(({ graphQLErrors, operation, forward }) => {
      if (!graphQLErrors) return;

      for (const { path, extensions } of graphQLErrors) {
        if (extensions?.code !== "UNAUTHENTICATED" || !path) continue;
        if (path.includes(refreshTokenPathName)) break;

        const { getContext, setContext } = operation;
        const context = getContext();

        setContext({
          ...context,
          headers: {
            ...context?.headers,
            _needsRefresh: true,
          },
        });

        return forward(operation);
      }
    }),
    setContext(async (_, previousContext) => {
      if (previousContext?.headers?._needsRefresh) {
        await refreshTokenRequestFunc();
      }

      return previousContext;
    }),
  ];
};

const refreshTokenReq = async () => {
  const refreshToken = useAuthStore.getState().refreshToken;
  // custom header
  const response = await client.mutate({
    mutation: LOGIN_WITH_REFRESH_TOKEN,
    variables: {},
    context: {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${refreshToken}`,
      },
    },
  });
  const res = response.data?.authLoginWithRefreshToken || {};
  useAuthStore.getState().setToken(res.accessToken);
  useAuthStore.getState().setRefreshToken(res.refreshToken);
};

// const errorLink = onError(
//   ({ graphQLErrors, networkError, operation, forward }) => {
//     if (graphQLErrors) {
//       for (let err of graphQLErrors) {
//         switch (err.statusCode) {
//           case 401:
//             useAuthStore.getState().logout();
//             break;
//         }
//       }
//     }
//   }
// );

const matrixEndpoint = new HttpLink({
  uri: import.meta.env.VITE_MATRIX_URL as string,
  headers: {
    "Content-Type": "application/json",
    authorization: "Api-Key test",
  },
});

const isRequestedClient = (clientName: string) => (op: Operation) =>
  op.getContext().clientName === clientName;

const defaultClient: keyof typeof clients = "api";

const clients = {
  matrix: matrixEndpoint,
  api: authLink.concat(
    createHttpLink({
      uri: import.meta.env.VITE_API_URL as string,
    })
  ),
};

const ClientResolverLink = Object.entries(clients)
  .map(([clientName, Link]) => [clientName, ApolloLink.from([Link])] as const)
  .reduce(
    ([_, PreviousLink], [clientName, NextLink]) => {
      const ChainedLink = ApolloLink.split(
        isRequestedClient(clientName),
        NextLink,
        PreviousLink
      );

      return [clientName, ChainedLink];
    },
    ["_default", clients[defaultClient]]
  )[1];

declare module "@apollo/client" {
  interface DefaultContext {
    clientName?: keyof typeof clients;
  }
}

const client = new ApolloClient({
  link: ApolloLink.from([
    ClientResolverLink,
    ...generateRefreshTokenLinkOnUnauthError({
      refreshTokenPathName: "refreshToken",
      refreshTokenRequestFunc: refreshTokenReq,
    }),
  ]),
  cache: new InMemoryCache(),
});

export default client;
