import {
  Query,
  QuerySocialReportUseCaseMemberAnalysisArgs,
} from "@/gql/graphql";
import { SOCIAL_REPORT_USE_CASE_MEMBER_ANALYSIS } from "@/gql/schema";
import { upperFirst } from "@/utils/string";
import { useQuery } from "@apollo/client";
import ReactApexChart from "react-apexcharts";
import Card, { CardBody } from "../ui/Card";

const MemberAnalytic = ({ id }: { id: string }) => {
  const { data } = useQuery<Query, QuerySocialReportUseCaseMemberAnalysisArgs>(
    SOCIAL_REPORT_USE_CASE_MEMBER_ANALYSIS,
    {
      variables: { id },
      skip: !id,
    }
  );
  const blocks = [
    {
      title: "TOTAL PHONE",
      value: data?.socialReportUseCaseMemberAnalysis?.totalPhones ?? 0,
    },
    {
      title: "TOTAL EMAIL",
      value: data?.socialReportUseCaseMemberAnalysis?.totalEmails ?? 0,
    },
    {
      title: "TOTAL DOB",
      value: data?.socialReportUseCaseMemberAnalysis?.totalDob ?? 0,
    },
    {
      title: "FACEBOOK NAME",
      value: data?.socialReportUseCaseMemberAnalysis?.totalFacebookNames ?? 0,
    },
  ];

  const relationshipChartSeries: ApexCharts.ApexOptions["series"] =
    data?.socialReportUseCaseMemberAnalysis?.relationshipAnalysis?.map(
      (i) => i.value
    ) || [];
  const relationshipChartOption: ApexCharts.ApexOptions = {
    legend: {
      position: "bottom",
      height: 30,
    },
    chart: {
      height: 300,
      type: "donut",
    },
    labels: data?.socialReportUseCaseMemberAnalysis?.relationshipAnalysis?.map(
      (i) => upperFirst(i.key)
    ),
    // colors: ["#1b73e8", "#0040ff", "#0040ff", "#0040ff", "#0040ff"],
    title: {
      text: "Relationship",
      style: {
        fontSize: "16px",
        color: "#000",
      },
    },
  };

  const genderChartSeries: ApexCharts.ApexOptions["series"] =
    data?.socialReportUseCaseMemberAnalysis?.genderAnalysis?.map(
      (i) => i.value
    ) || [];
  const genderChartOption: ApexCharts.ApexOptions = {
    legend: {
      position: "bottom",
      height: 30,
    },
    chart: {
      height: 300,
      type: "donut",
    },
    labels: data?.socialReportUseCaseMemberAnalysis?.genderAnalysis?.map((i) =>
      upperFirst(i.key)
    ),
    // colors: ["#1b73e8", "#0040ff", "#0040ff", "#0040ff", "#0040ff"],
    title: {
      text: "Gender",
      style: {
        fontSize: "16px",
        color: "#000",
      },
    },
  };
  const followerChartSeries: ApexCharts.ApexOptions["series"] =
    data?.socialReportUseCaseMemberAnalysis?.followerAnalysis?.map(
      (i) => i.value
    ) || [];
  const followerChartOption: ApexCharts.ApexOptions = {
    chart: {
      height: 300,
      type: "donut",
    },
    labels: data?.socialReportUseCaseMemberAnalysis?.followerAnalysis?.map(
      (i) => upperFirst(i.key)
    ),
    title: {
      text: "Follower",
      style: {
        fontSize: "16px",
        color: "#000",
      },
    },
  };

  const friendsChartSeries: ApexCharts.ApexOptions["series"] =
    data?.socialReportUseCaseMemberAnalysis?.friendAnalysis?.map(
      (i) => i.value
    ) || [];
  const friendsChartOption: ApexCharts.ApexOptions = {
    chart: {
      height: 300,
      type: "donut",
    },
    labels: data?.socialReportUseCaseMemberAnalysis?.friendAnalysis?.map((i) =>
      upperFirst(i.key)
    ),
    title: {
      text: "Friends",
      style: {
        fontSize: "16px",
        color: "#000",
      },
    },
  };
  const cityChartSeries: ApexCharts.ApexOptions["series"] = [
    {
      name: "City",
      data:
        data?.socialReportUseCaseMemberAnalysis?.cityAnalysis?.map(
          (i) => i.value
        ) || [],
    },
  ];
  const cityChartOption: ApexCharts.ApexOptions = {
    chart: {
      height: 350,
      type: "bar",
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      // formatter: function (val) {
      //   return val + "%";
      // },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },
    xaxis: {
      categories: data?.socialReportUseCaseMemberAnalysis?.cityAnalysis?.map(
        (i) => i.key
      ),
      position: "bottom",
      tooltip: {
        enabled: true,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
      },
    },
    title: {
      text: "City",
      style: {
        fontSize: "16px",
        color: "#000",
      },
    },
  };

  if (!data?.socialReportUseCaseMemberAnalysis) return null;
  return (
    <div>
      <div className="grid grid-cols-4 gap-[16px] mb-[16px]">
        {blocks.map((item) => (
          <Card
            key={item.title}
            className="!bg-[#1b73e8] rounded-[16px] shadow-md hover:shadow-lg"
          >
            <CardBody>
              <div className="flex flex-col items-center justify-between">
                <p className="text-center text-[16px] text-white font-bold">
                  {item.title}
                </p>
                <div className="text-center w-full text-[28px] mt-[8px] font-bold bg-white rounded-[8px] p-[8px]">
                  {item.value}
                </div>
              </div>
            </CardBody>
          </Card>
        ))}
      </div>
      <div className="grid grid-cols-3 gap-x-[16px] mb-[16px]">
        <div className="w-full">
          <div className="border-[1px] rounded-[16px] shadow-md bg-white border-[#0040ff] p-[8px]">
            <ReactApexChart
              options={relationshipChartOption}
              series={relationshipChartSeries}
              type="donut"
              height={300}
            />
          </div>
        </div>
        <div className="w-full">
          <div className="border-[1px] rounded-[16px] shadow-md bg-white border-[#0040ff] p-[8px]">
            <ReactApexChart
              options={genderChartOption}
              series={genderChartSeries}
              type="donut"
              height={300}
            />
          </div>
        </div>
        <div className="w-full">
          <div className="border-[1px] rounded-[16px] shadow-md bg-white border-[#0040ff] p-[8px]">
            <ReactApexChart
              options={followerChartOption}
              series={followerChartSeries}
              type="donut"
              height={300}
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-x-[16px]">
        <div className="w-full">
          <div className="border-[1px] rounded-[16px] shadow-md bg-white border-[#0040ff] p-[8px]">
            <ReactApexChart
              options={friendsChartOption}
              series={friendsChartSeries}
              type="donut"
              height={300}
            />
          </div>
        </div>
        <div className="col-span-2">
          <div className="border-[1px] rounded-[16px] shadow-md bg-white border-[#0040ff] p-[8px]">
            <ReactApexChart
              options={cityChartOption}
              series={cityChartSeries}
              type="bar"
              height={300}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemberAnalytic;
