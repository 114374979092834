import Icon from "@/components/icon/Icon";
import Breadcrumb from "@/components/layouts/Breadcrumb/Breadcrumb";
import Container from "@/components/layouts/Container/Container";
import Header, {
  HeaderLeft,
  HeaderRight,
} from "@/components/layouts/Header/Header";
import PageWrapper from "@/components/layouts/PageWrapper/PageWrapper";
import Button from "@/components/ui/Button";
import Card, {
  CardBody,
  CardHeader,
  CardHeaderChild,
  CardTitle,
} from "@/components/ui/Card";
import Table, { TBody, Td, Th, THead, Tr } from "@/components/ui/Table";
import {
  Product,
  PurchasedProduct,
  Query,
  QueryProductUseCaseDownloadPurchasedFileArgs,
  QueryProductUseCaseProductByIdsArgs,
} from "@/gql/graphql";
import {
  PRODUCT_USE_CASE_DOWNLOAD_FILE,
  PRODUCT_USE_CASE_PRODUCT_BY_IDS,
  PRODUCT_USE_CASE_PURCHASED_PRODUCTS,
} from "@/gql/schema";
import { useData } from "@/hooks/useData";
import { TableCardFooterTemplate } from "@/templates/common/TableParts.template";
import DefaultHeaderRightCommon from "@/templates/layouts/Headers/_common/DefaultHeaderRight.common";
import { useLazyQuery } from "@apollo/client";
import { createFileRoute, redirect } from "@tanstack/react-router";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useMemo, useState } from "react";
import toast from "react-hot-toast";

export const Route = createFileRoute("/purchased")({
  component: FacebookGroups,
  beforeLoad: ({ context, location }) => {
    if (!context.user) {
      throw redirect({
        to: "/login",
        search: {
          redirect: location.href,
        },
      });
    }
  },
});

const columnHelper = createColumnHelper<
  PurchasedProduct & { product: Product | undefined }
>();

function FacebookGroups() {
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const { data } = useData<Query, any>(PRODUCT_USE_CASE_PURCHASED_PRODUCTS);
  const [getDownloadFile] = useLazyQuery<
    Query,
    QueryProductUseCaseDownloadPurchasedFileArgs
  >(PRODUCT_USE_CASE_DOWNLOAD_FILE);
  const { data: productData } = useData<
    Query,
    QueryProductUseCaseProductByIdsArgs
  >(PRODUCT_USE_CASE_PRODUCT_BY_IDS, {
    variables: {
      productIds:
        data?.productUseCasePurchasedProducts?.map((item) => item.productId) ??
        [],
    },
    skip: !data?.productUseCasePurchasedProducts?.length,
  });

  const tableData = useMemo(() => {
    return data?.productUseCasePurchasedProducts?.map((item) => ({
      ...item,
      product: productData?.productUseCaseProductByIds?.find(
        (p) => p.id === item.productId
      ),
    }));
  }, [data, productData]);

  const downloadFile = async (productId: string) => {
    try {
      const res = await getDownloadFile({
        variables: {
          productId,
        },
      });
      if (res?.data?.productUseCaseDownloadPurchasedFile?.downloadUrl) {
        window.open(
          res.data.productUseCaseDownloadPurchasedFile.downloadUrl,
          "_blank"
        );
        return;
      }
      toast.error("Failed to download file");
    } catch (error: any) {
      toast.error(error?.message ?? "Failed to download file");
    }
  };

  const columns = [
    columnHelper.accessor("product", {
      cell: (info) => (
        <div className="text-[18px]">{info.getValue()?.name || "No Name"}</div>
      ),
      header: "Product",
      footer: "Product",
    }),
    columnHelper.accessor("product.price", {
      cell: (info) => (
        <div className="inline-flex items-center justify-center px-2 border-2 rounded-lg border-emerald-500 bg-emerald-500/10 text-emerald-500 border-transparent text-xl font-bold">
          {info.getValue()} USD
        </div>
      ),
      header: "Price",
      footer: "Price",
    }),
    columnHelper.accessor("product", {
      cell: (info) => <div className="text-[18px]">{"No Date"}</div>,
      header: "Report Date",
      footer: "Report Date",
    }),
    columnHelper.accessor("productId", {
      cell: (info) => (
        <Button
          icon="HeroArrowDownTray"
          onClick={() => downloadFile(info.getValue())}
          variant="solid"
          className="whitespace-nowrap"
        >
          Download Report
        </Button>
      ),
      header: "Download",
      footer: "Product",
    }),
  ];

  const table = useReactTable({
    data: tableData ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
  });

  return (
    <>
      <Header>
        <HeaderLeft>
          <Breadcrumb path="Home / Purchased" currentPage="Purchased" />
        </HeaderLeft>
        <HeaderRight>
          <DefaultHeaderRightCommon />
        </HeaderRight>
      </Header>
      <PageWrapper name="Purchased">
        <Container>
          <div className="col-span-12">
            <Card className="h-full">
              <CardHeader>
                <CardHeaderChild>
                  <CardTitle>Purchased</CardTitle>
                </CardHeaderChild>
              </CardHeader>
              <CardBody className="overflow-auto">
                <Table className="table-fixed max-md:min-w-[70rem]">
                  <THead>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <Tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                          <Th
                            key={header.id}
                            isColumnBorder={false}
                            className="text-left"
                          >
                            {header.isPlaceholder ? null : (
                              <div
                                key={header.id}
                                aria-hidden="true"
                                {...{
                                  className: header.column.getCanSort()
                                    ? "cursor-pointer select-none flex items-center"
                                    : "",
                                  onClick:
                                    header.column.getToggleSortingHandler(),
                                }}
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                                {{
                                  asc: (
                                    <Icon
                                      icon="HeroChevronUp"
                                      className="ltr:ml-1.5 rtl:mr-1.5"
                                    />
                                  ),
                                  desc: (
                                    <Icon
                                      icon="HeroChevronDown"
                                      className="ltr:ml-1.5 rtl:mr-1.5"
                                    />
                                  ),
                                }[header.column.getIsSorted() as string] ??
                                  null}
                              </div>
                            )}
                          </Th>
                        ))}
                      </Tr>
                    ))}
                  </THead>
                  <TBody>
                    {table.getRowModel().rows.map((row) => (
                      <Tr key={row.id}>
                        {row.getVisibleCells().map((cell) => (
                          <Td key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Td>
                        ))}
                      </Tr>
                    ))}
                  </TBody>
                </Table>
                <div className="w-full mt-[8px]">
                  <TableCardFooterTemplate table={table} />
                </div>
              </CardBody>
            </Card>
          </div>
        </Container>
      </PageWrapper>
    </>
  );
}
